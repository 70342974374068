import React, { useEffect } from "react";
import { Link } from "react-router-dom";

export const NoResource = () => {
  useEffect(() => {
    document.title = "Hub | 404";
    setBreadcrumb();
  }, []);
  const setBreadcrumb = () => {
    const breadcrumbDiv = document.getElementsByClassName("layout-breadcrumb viewname")[0];
    const breadcrumbText = `Resource is not available`;
    breadcrumbDiv.innerHTML = breadcrumbText;
  };

  return (
    <div style={{ margin: "30 auto 0 auto" }}>
      <div className="card">
        <div
          style={{
            margin: "12% auto",
            padding: 40,
            width: 350,
            textAlign: "center",
            border: "1px solid lightgrey"
          }}>
          <h5>NOT FOUND</h5>
          <p>Requested resource is not available.</p>
          <p style={{ fontSize: 20 }}>
            <Link to="/">Back to Main Page</Link>
          </p>
        </div>

        <div style={{ marginTop: "30%" }}></div>
      </div>
    </div>
  );
};
