import React from "react";
import { Link } from "react-router-dom";
import AppSubmenu from "./AppSubmenu";

const AppMenu = (props) => {
  return (
    <div className="layout-sidebar" onClick={props.onMenuClick}>
      {/* <Link to="/" className="logo">
                <img id="app-logo" className="logo-image" src="assets/layout/images/logo-supponor-168x170.png" alt="Supponor Hub" />
                <span className="app-name">Supponor Hub</span>
                
            </Link>
             */}
      <Link to="/" className="logo">
        <img
          className="logo-image customer-logo"
          src="assets/layout/images/nhl-logo.png"
          alt="NHL Logo"
        />
        <img
          id="app-logo"
          className="logo-image"
          src="assets/layout/images/Supponor-logo-whiteout-1.png"
          alt="Supponor Hub"
        />
      </Link>
      <div className="layout-menu-container">
        <AppSubmenu
          items={props.model}
          menuMode={props.menuMode}
          parentMenuItemActive
          menuActive={props.active}
          mobileMenuActive={props.mobileMenuActive}
          root
          onMenuitemClick={props.onMenuitemClick}
          onRootMenuitemClick={props.onRootMenuitemClick}
        />
      </div>
    </div>
  );
};

export default AppMenu;
