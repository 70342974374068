export const APP_VERSION = "2.1.56 (staging)";
export const APP_DOMAIN = "nhl-staging.supponor.com";
export const SHARE_DOMAIN = "https://master.d3q4qnqvqne78h.amplifyapp.com";
export const ENVIRONMENT_NAME = "test";
export const userRoles = [
  //'admin', 'user'
  { name: "Admin", code: "admin" },
  { name: "User", code: "user" }
];
export const userScopes = [
  //'club', 'league', 'root'
  { name: "Club", code: "club" },
  { name: "League", code: "league" },
  { name: "Root", code: "root" }
];
export const HUB_TYPE = "NHL";
export const BACKEND_LAMBDA = "hub2-api-venues-get";
export const EXPORT_LAMBDA = "hub2-stage-export-handler";
export const TRANSCODE_LAMBDA = "hub2-stage-media-handler";

export const CLOUDFRONT_PREFIX = 'https://d33k61efjqbbsg.cloudfront.net/';
export const ASSET_PATH = "staging/ads/";
export const MEDIA_BUCKET = "hub2-storage";

export const SV_INTERVAL_INDEXES = [
  // 0, 7, 14, 21, 28, 35,
  // 40, 47, 54, 61, 68, 75,
  // 80, 87, 94, 101, 108, 115,
  0, 6, 13, 20, 27, 34, 40, 46, 53, 60, 67, 74, 80, 86, 93, 100, 107, 114, 120, 130
];

export const MENU_ITEMS_GENERAL = [
  { label: "Adverts", icon: "pi pi-fw pi-images", to: "/adverts", id: 1 },
  { label: "Advertisers", icon: "pi pi-fw pi-bookmark", to: "/advertisers", id: 2 },
  { label: "Analytics", icon: "pi pi-fw pi-chart-bar", to: "/empty", id: 3 },
  { label: "Competitions", icon: "pi pi-fw pi-list", to: "/competitions", id: 4 },
  { label: "Dashboard", icon: "pi pi-fw pi-calendar-times", to: "/calendar", id: 5 },
  { label: "Media", icon: "pi pi-fw pi-image", to: "/media", id: 6 },
  { label: "Reporting", icon: "pi pi-fw pi-share-alt", to: "/reporting", id: 7 },
  { label: "Sports", icon: "pi pi-fw pi-clock", to: "/sports", id: 8 },
  { label: "Templates", icon: "pi pi-fw pi-book", to: "/templates", id: 9 },
  { label: "Venues", icon: "pi pi-fw pi-map", to: "/venues", id: 10 },
  { label: "Users", icon: "pi pi-fw pi-users", to: "/users", id: 11 },
  { label: "Noticeboard", icon: "pi pi-fw pi-external-link", to: "/empty", id: 12 },
  { label: "Approvals", icon: "pi pi-fw pi-thumbs-up", to: "/approvals", id: 13 }
];

export const MENU_ITEMS_NHL = [
  { label: "Game Schedule", icon: "pi pi-fw pi-calendar-times", to: "/calendar", id: 1 },
  { label: "Advertisers", icon: "pi pi-fw pi-bookmark", to: "/advertisers", id: 2 },
  { label: "LOS Signage", icon: "pi pi-fw pi-print", to: "/empty", id: 4 },
  { label: "DED Ads", icon: "pi pi-fw pi-images", to: "/adverts", id: 3 },
  { label: "Slot Virtual Ads", icon: "pi pi-fw pi-image", to: "/slot-virtual", id: 12 },
  { label: "Reporting", icon: "pi pi-fw pi-chart-bar", to: "/reporting", id: 5 },
  { label: "LOS Guidelines", icon: "pi pi-fw pi-list", to: "/guidelines", id: 13 },
  { label: "FAQ", icon: "pi pi-fw pi-question-circle", to: "/faq", id: 15 },
  { label: "Contacts", icon: "pi pi-fw pi-info-circle", to: "/contacts", id: 14 },
  { label: "Seasons", icon: "pi pi-fw pi-list", to: "/competitions", id: 6 },
  { label: "Templates", icon: "pi pi-fw pi-book", to: "/templates", id: 7 },
  { label: "Venues", icon: "pi pi-fw pi-map", to: "/venues", id: 8 },
  { label: "Users", icon: "pi pi-fw pi-users", to: "/users", id: 9 },
  { label: "Noticeboard", icon: "pi pi-fw pi-external-link", to: "/empty", id: 10 },
  { label: "Approvals", icon: "pi pi-fw pi-thumbs-up", to: "/approvals", id: 11 }
];

export const MENU_ITEMS = HUB_TYPE === "NHL" ? MENU_ITEMS_NHL : MENU_ITEMS_GENERAL;

export const CLUBS = [
  {
    id: 1,
    name: "National Hockey League",
    code: "NHL",
    opt_out: true,
    share: "min",
    country: "US"
  },
  { id: 2, name: "Anaheim Ducks", code: "ANA", opt_out: true, share: "max", country: "US" },
  { id: 3, name: "Arizona Coyotes", code: "ARI", opt_out: false, share: "max", country: "US" },
  { id: 4, name: "Boston Bruins", code: "BOS", opt_out: true, share: "min", country: "US" },
  { id: 5, name: "Buffalo Sabres", code: "BUF", opt_out: true, share: "no", country: "US" },
  { id: 6, name: "Calgary Flames", code: "CGY", opt_out: true, share: "no", country: "CA" },
  { id: 7, name: "Carolina Hurricanes", code: "CAR", opt_out: false, share: "max", country: "US" },
  { id: 8, name: "Chicago Blackhawks", code: "CHI", opt_out: true, share: "no", country: "US" },
  { id: 9, name: "Colorado Avalanche", code: "COL", opt_out: false, share: "max", country: "US" },
  {
    id: 10,
    name: "Columbus Blue Jackets",
    code: "CBJ",
    opt_out: false,
    share: "max",
    country: "US"
  },
  { id: 11, name: "Dallas Stars", code: "DAL", opt_out: false, share: "min", country: "US" },
  { id: 12, name: "Detroit Red Wings", code: "DET", opt_out: true, share: "min", country: "US" },
  { id: 13, name: "Edmonton Oilers", code: "EDM", opt_out: true, share: "no", country: "CA" },
  { id: 14, name: "Florida Panthers", code: "FLA", opt_out: true, share: "max", country: "US" },
  { id: 15, name: "Los Angeles Kings", code: "LAK", opt_out: true, share: "min", country: "US" },
  { id: 16, name: "Minnesota Wild", code: "MIN", opt_out: false, share: "min", country: "US" },
  { id: 17, name: "Montreal Canadiens", code: "MTL", opt_out: true, share: "no", country: "CA" },
  { id: 18, name: "Nashville Predators", code: "NSH", opt_out: false, share: "max", country: "US" },
  { id: 19, name: "New Jersey Devils", code: "NJD", opt_out: false, share: "min", country: "US" },
  { id: 20, name: "New York Islanders", code: "NYI", opt_out: false, share: "max", country: "US" },
  { id: 21, name: "New York Rangers", code: "NYR", opt_out: true, share: "no", country: "US" },
  { id: 22, name: "Ottawa Senators", code: "OTT", opt_out: true, share: "no", country: "CA" },
  { id: 23, name: "Philadelphia Flyers", code: "PHI", opt_out: true, share: "min", country: "US" },
  { id: 24, name: "Pittsburgh Penguins", code: "PIT", opt_out: false, share: "max", country: "US" },
  { id: 25, name: "San Jose Sharks", code: "SJS", opt_out: false, share: "min", country: "US" },
  { id: 26, name: "Seattle Kraken", code: "SEA", opt_out: true, share: "min", country: "US" },
  { id: 27, name: "St Louis Blues", code: "STL", opt_out: false, share: "max", country: "US" },
  { id: 28, name: "Tampa Bay Lightning", code: "TBL", opt_out: false, share: "max", country: "US" },
  { id: 29, name: "Toronto Maple Leafs", code: "TOR", opt_out: true, share: "no", country: "CA" },
  { id: 30, name: "Vancouver Canucks", code: "VAN", opt_out: true, share: "no", country: "CA" },
  {
    id: 31,
    name: "Vegas Golden Knights",
    code: "VGK",
    opt_out: false,
    share: "max",
    country: "US"
  },
  { id: 32, name: "Washington Capitals", code: "WSH", opt_out: true, share: "max", country: "US" },
  { id: 33, name: "Winnipeg Jets", code: "WPG", opt_out: false, share: "max", country: "CA" }
];

export const NETWORKS = [
  { code: "ABC", name: "ABC", isUS: true, isNational: true, isEnglish: true },
  { code: "ESPN", name: "ESPN", isUS: true, isNational: true, isEnglish: true },
  { code: "ESPN+", name: "ESPN+", isUS: true, isNational: true, isEnglish: true },
  { code: "HULU", name: "Hulu", isUS: true, isNational: true, isEnglish: true },
  { code: "TNT", name: "TNT", isUS: true, isNational: true, isEnglish: true },
  { code: "NHLN", name: "NHL Network", isUS: true, isNational: true, isEnglish: true },
  { code: "ALT", name: "Altitude", isUS: true, isNational: false, isEnglish: true },
  { code: "ALT2", name: "Altitude 2", isUS: true, isNational: false, isEnglish: true },
  {
    code: "ATTSN-PT",
    name: "AT&T SportsNet Pittsburgh ",
    isUS: true,
    isNational: false,
    isEnglish: true
  },
  {
    code: "ATTSN-RM",
    name: "AT&T SportsNet Rocky Mountain",
    isUS: true,
    isNational: false,
    isEnglish: true
  },
  { code: "BSAZ", name: "Bally Sports Arizona", isUS: true, isNational: false, isEnglish: true },
  {
    code: "BSAZX",
    name: "Bally Sports Arizona Extra",
    isUS: true,
    isNational: false,
    isEnglish: true
  },
  {
    code: "BSAZX 2",
    name: "Bally Sports Arizona Extra 2",
    isUS: true,
    isNational: false,
    isEnglish: true
  },
  { code: "BSDET", name: "Bally Sports Detroit", isUS: true, isNational: false, isEnglish: true },
  {
    code: "BSDETX",
    name: "Bally Sports Detroit Extra",
    isUS: true,
    isNational: false,
    isEnglish: true
  },
  { code: "BSFL", name: "Bally Sports Florida", isUS: true, isNational: false, isEnglish: true },
  { code: "BSMW", name: "Bally Sports Midwest", isUS: true, isNational: false, isEnglish: true },
  {
    code: "BSMWX",
    name: "Bally Sports Midwest Extra",
    isUS: true,
    isNational: false,
    isEnglish: true
  },
  { code: "BSN", name: "Bally Sports North", isUS: true, isNational: false, isEnglish: true },
  {
    code: "BSNX",
    name: "Bally Sports North Extra",
    isUS: true,
    isNational: false,
    isEnglish: true
  },
  { code: "BSOH", name: "Bally Sports Ohio", isUS: true, isNational: false, isEnglish: true },
  { code: "BSSC", name: "Bally Sports SoCal", isUS: true, isNational: false, isEnglish: true },
  { code: "BSSD", name: "Bally Sports San Diego ", isUS: true, isNational: false, isEnglish: true },
  { code: "BSSO", name: "Bally Sports South", isUS: true, isNational: false, isEnglish: true },
  { code: "BSSUN", name: "Bally Sports Sun", isUS: true, isNational: false, isEnglish: true },
  { code: "BSSW", name: "Bally Sports Southwest", isUS: true, isNational: false, isEnglish: true },
  {
    code: "BSSWX",
    name: "Bally Sports Southwest Extra",
    isUS: true,
    isNational: false,
    isEnglish: true
  },
  { code: "BSW", name: "Bally Sports West", isUS: true, isNational: false, isEnglish: true },
  { code: "BSWI", name: "Bally Sports Wisconsin ", isUS: true, isNational: false, isEnglish: true },
  {
    code: "BSWIX",
    name: "Bally Sports Wisconsin Extra",
    isUS: true,
    isNational: false,
    isEnglish: true
  },
  { code: "KCOP-13", name: "KCOP", isUS: true, isNational: false, isEnglish: true },
  { code: "KTXA", name: "KTXA", isUS: true, isNational: false, isEnglish: true },
  { code: "KTNV", name: "KTNV", isUS: true, isNational: false, isEnglish: true },
  { code: "MSG", name: "MSG", isUS: true, isNational: false, isEnglish: true },
  { code: "MSG+", name: "MSG+", isUS: true, isNational: false, isEnglish: true },
  { code: "MSG+ 2", name: "MSG+ 2", isUS: true, isNational: false, isEnglish: true },
  { code: "MSG 2", name: "MSG 2", isUS: true, isNational: false, isEnglish: true },
  { code: "MSG-B", name: "MSG Buffalo", isUS: true, isNational: false, isEnglish: true },
  { code: "NBCSCA", name: "NBC Sports California", isUS: true, isNational: false, isEnglish: true },
  { code: "NBCSCH", name: "NBC Sports Chicago ", isUS: true, isNational: false, isEnglish: true },
  { code: "NBCSCH+", name: "NBC Sports Chicago+", isUS: true, isNational: false, isEnglish: true },
  {
    code: "NBCSCH+ 2",
    name: "NBC Sports Chicago+ 2",
    isUS: true,
    isNational: false,
    isEnglish: true
  },
  {
    code: "NBCSP",
    name: "NBC Sports Philadelphia",
    isUS: true,
    isNational: false,
    isEnglish: true
  },
  {
    code: "NBCSP+",
    name: "NBC Sports Philadelphia+",
    isUS: true,
    isNational: false,
    isEnglish: true
  },
  { code: "NBCSWA", name: "NBC Sports Washington", isUS: true, isNational: false, isEnglish: true },
  {
    code: "NBCSWA+",
    name: "NBC Sports Washington+",
    isUS: true,
    isNational: false,
    isEnglish: true
  },
  { code: "NESN", name: "NESN", isUS: true, isNational: false, isEnglish: true },
  { code: "NESNplus", name: "NESN+", isUS: true, isNational: false, isEnglish: true },
  {
    code: "ROOT-NW",
    name: "Root Sports Northwest",
    isUS: true,
    isNational: false,
    isEnglish: true
  },
  {
    code: "ROOT-NW+",
    name: "Root Sports Northwest+",
    isUS: true,
    isNational: false,
    isEnglish: true
  },
  { code: "CBC", name: "CBC", isUS: false, isNational: true, isEnglish: true },
  { code: "CITY", name: "Citytv", isUS: false, isNational: true, isEnglish: true },
  { code: "SN", name: "Sportsnet", isUS: false, isNational: true, isEnglish: true },
  { code: "SN1", name: "Sportsnet One", isUS: false, isNational: true, isEnglish: true },
  { code: "SN360", name: "Sportsnet 360", isUS: false, isNational: true, isEnglish: true },
  { code: "TVAS", name: "TVA Sports ", isUS: false, isNational: true, isEnglish: false },
  { code: "TVAS2", name: "TVA Sports 2", isUS: false, isNational: true, isEnglish: false },
  { code: "RDS", name: "RDS", isUS: false, isNational: false, isEnglish: false },
  { code: "RDS2", name: "RDS2", isUS: false, isNational: false, isEnglish: false },
  { code: "SNE", name: "Sportsnet East", isUS: false, isNational: false, isEnglish: true },
  { code: "SNF", name: "Sportsnet Flames", isUS: false, isNational: false, isEnglish: true },
  { code: "SNO", name: "Sportsnet Ontario", isUS: false, isNational: false, isEnglish: true },
  { code: "SNP", name: "Sportsnet Pacific", isUS: false, isNational: false, isEnglish: true },
  { code: "SNW", name: "Sportsnet West", isUS: false, isNational: false, isEnglish: true },
  { code: "TSN2", name: "TSN2", isUS: false, isNational: false, isEnglish: true },
  { code: "TSN3", name: "TSN3", isUS: false, isNational: false, isEnglish: true },
  { code: "TSN4", name: "TSN4", isUS: false, isNational: false, isEnglish: true },
  { code: "TSN5", name: "TSN5", isUS: false, isNational: false, isEnglish: true }
];

const ALL_INTERVAL_INDEXES = [
  1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27,
  28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52,
  53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76,
  77, 78, 79, 80, 81, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101,
  102, 103, 104, 105, 106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
  121, 122, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 135
];

export const SHARE_MODELS = {
  "RS-H-1": {
    nhl: [
      1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26,
      27, 28, 29, 30, 31, 33, 34, 35, 36, 37, 38, 39, 40, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51,
      52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 74, 75,
      76, 77, 78, 79, 80, 81, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99,
      100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 111, 112, 113, 115, 116, 117, 118, 119,
      120, 121, 122, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 135
    ],
    home: [32, 73, 114],
    // all RO
    away: ALL_INTERVAL_INDEXES
  },
  // NHL: 32
  // away: all except for 32
  // home: all RO
  "RS-A-1": {
    nhl: [
      1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26,
      27, 28, 29, 30, 31, 33, 34, 35, 36, 37, 38, 39, 40, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51,
      52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 74, 75,
      76, 77, 78, 79, 80, 81, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99,
      100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 111, 112, 113, 115, 116, 117, 118, 119,
      120, 121, 122, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 135
    ],
    // all RO
    home: ALL_INTERVAL_INDEXES,
    away: [32, 73, 114]
  },
  // NHL: 32
  // away: [1-16], [27-31], [33-40]
  // home: 17-26
  "RS-A-2": {
    nhl: [
      1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26,
      27, 28, 29, 30, 31, 33, 34, 35, 36, 37, 38, 39, 40, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51,
      52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 74, 75,
      76, 77, 78, 79, 80, 81, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99,
      100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 111, 112, 113, 115, 116, 117, 118, 119,
      120, 121, 122, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 135
    ],
    home: [
      1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36,
      37, 38, 39, 40, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 68, 69, 70,
      71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94,
      95, 96, 97, 98, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120, 121, 122, 124,
      125, 126, 127, 128, 129, 130, 131, 132, 133, 135
    ],
    away: [
      17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 32, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 73, 99,
      100, 101, 102, 103, 104, 105, 106, 107, 108, 114
    ]
  },
  // NHL: 32
  // away: [1-16], [23-31], [33-40]
  // home: 17-22
  "RS-A-3": {
    nhl: [
      1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26,
      27, 28, 29, 30, 31, 33, 34, 35, 36, 37, 38, 39, 40, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51,
      52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 74, 75,
      76, 77, 78, 79, 80, 81, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99,
      100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 111, 112, 113, 115, 116, 117, 118, 119,
      120, 121, 122, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 135
    ],
    home: [
      1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32,
      33, 34, 35, 36, 37, 38, 39, 40, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56,
      57, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 83, 84, 85, 86,
      87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 105, 106, 107, 108, 109, 110, 111, 112, 113,
      114, 115, 116, 117, 118, 119, 120, 121, 122, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133,
      135
    ],
    away: [17, 18, 19, 20, 21, 22, 32, 58, 59, 60, 61, 62, 63, 73, 99, 100, 101, 102, 103, 104, 114]
  },
  // NHL: 32
  // away: [1-16], [21-31], [33-40]
  // home: 17-20
  "RS-A-4": {
    nhl: [
      1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26,
      27, 28, 29, 30, 31, 33, 34, 35, 36, 37, 38, 39, 40, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51,
      52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 74, 75,
      76, 77, 78, 79, 80, 81, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99,
      100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 111, 112, 113, 115, 116, 117, 118, 119,
      120, 121, 122, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 135
    ],
    home: [
      1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30,
      31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54,
      55, 56, 57, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81,
      83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 103, 104, 105, 106, 107, 108,
      109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120, 121, 122, 124, 125, 126, 127, 128,
      129, 130, 131, 132, 133, 135
    ],
    away: [17, 18, 19, 20, 32, 58, 59, 60, 61, 73, 99, 100, 101, 102, 114]
  },

  // NHL: [8-16], [32-40]
  // home: [1-7], [17-31]
  "RS-USA-N-1": {
    nhl: [
      1, 2, 3, 4, 5, 6, 7, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 42, 43, 44,
      45, 46, 47, 48, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 83, 84, 85, 86,
      87, 88, 89, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 111, 112, 113
    ],
    home: [
      8, 9, 10, 11, 12, 13, 14, 15, 16, 32, 33, 34, 35, 36, 37, 38, 39, 40, 49, 50, 51, 52, 53, 54,
      55, 56, 57, 73, 74, 75, 76, 77, 78, 79, 80, 81, 90, 91, 92, 93, 94, 95, 96, 97, 98, 114, 115,
      116, 117, 118, 119, 120, 121, 122, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 135
    ],
    // all RO
    away: ALL_INTERVAL_INDEXES
  },
  // NHL: all
  "RS-USA-N-2": {
    nhl: [],
    // all RO
    home: ALL_INTERVAL_INDEXES,
    // all RO
    away: ALL_INTERVAL_INDEXES
  },

  // NHL: [1-16], [21-40]
  // home: [17-20]
  "RS-USA-N-3": {
    nhl: [17, 18, 19, 20, 58, 59, 60, 61, 99, 100, 101, 102],
    home: [
      1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30,
      31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54,
      55, 56, 57, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81,
      83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 103, 104, 105, 106, 107, 108,
      109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120, 121, 122, 124, 125, 126, 127, 128,
      129, 130, 131, 132, 133, 135
    ],
    // all RO
    away: ALL_INTERVAL_INDEXES
  },

  // NHL: 16, 32
  // home: all except for 16, 32
  // away: all RO

  "RS-CDN-N-4": {
    nhl: [
      1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27,
      28, 29, 30, 31, 33, 34, 35, 36, 37, 38, 39, 40, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52,
      53, 54, 55, 56, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 74, 75, 76, 77,
      78, 79, 80, 81, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 99, 100, 101, 102,
      103, 104, 105, 106, 107, 108, 109, 110, 111, 112, 113, 115, 116, 117, 118, 119, 120, 121, 122,
      124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 135
    ],

    home: [16, 32, 57, 73, 98, 114],

    // all RO
    away: ALL_INTERVAL_INDEXES
  },

  // same as RS-USA-N-2
  "RS-CDN-N-5": {
    nhl: [],
    // all RO
    home: ALL_INTERVAL_INDEXES,
    // all RO
    away: ALL_INTERVAL_INDEXES
  },

  // same as RS-USA-N-3
  "RS-CDN-N-6": {
    nhl: [17, 18, 19, 20, 58, 59, 60, 61, 99, 100, 101, 102],
    home: [
      1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30,
      31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54,
      55, 56, 57, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81,
      83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 103, 104, 105, 106, 107, 108,
      109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120, 121, 122, 124, 125, 126, 127, 128,
      129, 130, 131, 132, 133, 135
    ],
    // all RO
    away: ALL_INTERVAL_INDEXES
  },

  "RS-INT-1": {
    nhl: [],
    // all RO
    home: [
      1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26,
      27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 42, 43, 44, 45, 46, 47, 48, 49, 50,
      51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73,
      74, 75, 76, 77, 78, 79, 80, 81, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97,
      98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116,
      117, 118, 119, 120, 121, 122, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 135
    ],
    // all RO
    away: [
      1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26,
      27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 42, 43, 44, 45, 46, 47, 48, 49, 50,
      51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73,
      74, 75, 76, 77, 78, 79, 80, 81, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97,
      98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116,
      117, 118, 119, 120, 121, 122, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 135
    ]
  }
};
// NHL: 32
// away: all except for 32
// home: all RO
export const RS_A_1 = {
  nhl: [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26,
    27, 28, 29, 30, 31, 33, 34, 35, 36, 37, 38, 39, 40, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52,
    53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 74, 75, 76, 77,
    78, 79, 80, 81, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101,
    102, 103, 104, 105, 106, 107, 108, 109, 110, 111, 112, 113, 115, 116, 117, 118, 119, 120, 121,
    122, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 135
  ],
  // all RO
  home: ALL_INTERVAL_INDEXES,
  away: [32, 73, 114]
};
// NHL: 32
// away: [1-16], [27-31], [33-40]
// home: 17-26
export const RS_A_2 = {
  nhl: [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26,
    27, 28, 29, 30, 31, 33, 34, 35, 36, 37, 38, 39, 40, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52,
    53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 74, 75, 76, 77,
    78, 79, 80, 81, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101,
    102, 103, 104, 105, 106, 107, 108, 109, 110, 111, 112, 113, 115, 116, 117, 118, 119, 120, 121,
    122, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 135
  ],
  home: [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36,
    37, 38, 39, 40, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 68, 69, 70, 71,
    72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96,
    97, 98, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120, 121, 122, 124, 125, 126,
    127, 128, 129, 130, 131, 132, 133, 135
  ],
  away: [
    17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 32, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 73, 99, 100,
    101, 102, 103, 104, 105, 106, 107, 108, 114
  ]
};
// NHL: 32
// away: [1-16], [23-31], [33-40]
// home: 17-22
export const RS_A_3 = {
  nhl: [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26,
    27, 28, 29, 30, 31, 33, 34, 35, 36, 37, 38, 39, 40, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52,
    53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 74, 75, 76, 77,
    78, 79, 80, 81, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101,
    102, 103, 104, 105, 106, 107, 108, 109, 110, 111, 112, 113, 115, 116, 117, 118, 119, 120, 121,
    122, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 135
  ],
  home: [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32,
    33, 34, 35, 36, 37, 38, 39, 40, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57,
    64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 83, 84, 85, 86, 87, 88,
    89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 105, 106, 107, 108, 109, 110, 111, 112, 113, 114, 115,
    116, 117, 118, 119, 120, 121, 122, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 135
  ],
  away: [17, 18, 19, 20, 21, 22, 32, 58, 59, 60, 61, 62, 63, 73, 99, 100, 101, 102, 103, 104, 114]
};
// NHL: 32
// away: [1-16], [21-31], [33-40]
// home: 17-20
export const RS_A_4 = {
  nhl: [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26,
    27, 28, 29, 30, 31, 33, 34, 35, 36, 37, 38, 39, 40, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52,
    53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 74, 75, 76, 77,
    78, 79, 80, 81, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101,
    102, 103, 104, 105, 106, 107, 108, 109, 110, 111, 112, 113, 115, 116, 117, 118, 119, 120, 121,
    122, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 135
  ],
  home: [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30,
    31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55,
    56, 57, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 83, 84,
    85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 103, 104, 105, 106, 107, 108, 109, 110,
    111, 112, 113, 114, 115, 116, 117, 118, 119, 120, 121, 122, 124, 125, 126, 127, 128, 129, 130,
    131, 132, 133, 135
  ],
  away: [17, 18, 19, 20, 32, 58, 59, 60, 61, 73, 99, 100, 101, 102, 114]
};

// NHL: [8-16], [32-40]
// home: [1-7], [17-31]
export const RS_USA_N_1 = {
  nhl: [
    1, 2, 3, 4, 5, 6, 7, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 42, 43, 44, 45,
    46, 47, 48, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 83, 84, 85, 86, 87, 88,
    89, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 111, 112, 113
  ],
  home: [
    8, 9, 10, 11, 12, 13, 14, 15, 16, 32, 33, 34, 35, 36, 37, 38, 39, 40, 49, 50, 51, 52, 53, 54,
    55, 56, 57, 73, 74, 75, 76, 77, 78, 79, 80, 81, 90, 91, 92, 93, 94, 95, 96, 97, 98, 114, 115,
    116, 117, 118, 119, 120, 121, 122, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 135
  ],
  // all RO
  away: ALL_INTERVAL_INDEXES
};
// NHL: all
export const RS_USA_N_2 = {
  nhl: [],
  // all RO
  home: ALL_INTERVAL_INDEXES,
  // all RO
  away: ALL_INTERVAL_INDEXES
};

// NHL: [1-16], [21-40]
// home: [17-20]
export const RS_USA_N_3 = {
  nhl: [17, 18, 19, 20, 58, 59, 60, 61, 99, 100, 101, 102],
  home: [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30,
    31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55,
    56, 57, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 83, 84,
    85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 103, 104, 105, 106, 107, 108, 109, 110,
    111, 112, 113, 114, 115, 116, 117, 118, 119, 120, 121, 122, 124, 125, 126, 127, 128, 129, 130,
    131, 132, 133, 135
  ],
  // all RO
  away: ALL_INTERVAL_INDEXES
};

// NHL: 16, 32
// home: all except for 16, 32
// away: all RO

export const RS_CDN_N_4 = {
  nhl: [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27,
    28, 29, 30, 31, 33, 34, 35, 36, 37, 38, 39, 40, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53,
    54, 55, 56, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 74, 75, 76, 77, 78, 79,
    80, 81, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 99, 100, 101, 102, 103, 104,
    105, 106, 107, 108, 109, 110, 111, 112, 113, 115, 116, 117, 118, 119, 120, 121, 122, 124, 125,
    126, 127, 128, 129, 130, 131, 132, 133, 135
  ],

  home: [16, 32, 57, 73, 98, 114],

  // all RO
  away: ALL_INTERVAL_INDEXES
};
export const RS_CDN_N_5 = RS_USA_N_2;
export const RS_CDN_N_6 = RS_USA_N_3;

export const RS_INT_1 = {
  nhl: [],
  // all RO
  home: [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26,
    27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51,
    52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75,
    76, 77, 78, 79, 80, 81, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100,
    101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119,
    120, 121, 122, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 135
  ],
  // all RO
  away: [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26,
    27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51,
    52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75,
    76, 77, 78, 79, 80, 81, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100,
    101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119,
    120, 121, 122, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 135
  ]
};

export const CATEGORIES = [
  { id: 1, name: "Agriculture" },
  { id: 2, name: "Airline" },
  { id: 3, name: "Apartments" },
  { id: 4, name: "Apparel Athletic" },
  { id: 5, name: "Apparel Non-Athletic" },
  { id: 6, name: "Apparel Retail" },
  { id: 7, name: "Armed Forces" },
  { id: 8, name: "Auto Dealer" },
  { id: 9, name: "Auto Manufacturer" },
  { id: 10, name: "Auto Parts" },
  { id: 11, name: "Auto Repair" },
  { id: 12, name: "Auto Tires" },
  { id: 13, name: "Banking" },
  { id: 14, name: "Beer" },
  { id: 15, name: "Beverage Retail" },
  { id: 16, name: "Beverage Service" },
  { id: 17, name: "Car Rental" },
  { id: 18, name: "Casino" },
  { id: 19, name: "Commercial Product" },
  { id: 20, name: "Computer Hardware" },
  { id: 21, name: "Computer Software" },
  { id: 22, name: "Construction" },
  { id: 23, name: "Consulting" },
  { id: 24, name: "Consumer Product" },
  { id: 25, name: "Corporate Housing" },
  { id: 26, name: "Credit Card" },
  { id: 27, name: "Cryptocurrency/Non-Fungible Tokens" },
  { id: 28, name: "Daily Fantasy" },
  { id: 29, name: "Dental Service" },
  { id: 30, name: "Department Retail" },
  { id: 31, name: "Dine-In Restaurant" },
  { id: 32, name: "Distilled Beverage" },
  { id: 33, name: "Distributor/Bottler" },
  { id: 34, name: "Drug/Pharmaceutical Product" },
  { id: 35, name: "Drug/Pharmaceutical Retail" },
  { id: 36, name: "Education" },
  { id: 37, name: "Electronics Product" },
  { id: 38, name: "Electronics Retail" },
  { id: 39, name: "Energy Drink" },
  { id: 40, name: "Entertainment" },
  { id: 41, name: "Financial Service" },
  { id: 42, name: "Food Product" },
  { id: 43, name: "Food Service" },
  { id: 44, name: "Government" },
  { id: 45, name: "Grocery Store" },
  { id: 46, name: "Healthcare" },
  { id: 47, name: "Home Builder" },
  { id: 48, name: "Home Product" },
  { id: 49, name: "Home Retail" },
  { id: 50, name: "Home Service" },
  { id: 51, name: "Hospital" },
  { id: 52, name: "Hotel/Motel" },
  { id: 53, name: "Insurance" },
  { id: 54, name: "Internet Service Provider" },
  { id: 55, name: "Lottery" },
  { id: 56, name: "Mortgage" },
  { id: 57, name: "Non-Profit" },
  { id: 58, name: "Office Retail" },
  { id: 59, name: "Oil & Gas" },
  { id: 60, name: "Organization--Other" },
  { id: 61, name: "Product--Other" },
  { id: 62, name: "Professional Service" },
  { id: 63, name: "Publishing" },
  { id: 64, name: "Quick Service Restaurant" },
  { id: 65, name: "R.V. Manufacturer" },
  { id: 66, name: "Radio Network" },
  { id: 67, name: "Real Estate" },
  { id: 68, name: "Retail--Other" },
  { id: 69, name: "Service--Other" },
  { id: 70, name: "Shipping" },
  { id: 71, name: "Soft Drink" },
  { id: 72, name: "Sports Betting" },
  { id: 73, name: "Sports Drink" },
  { id: 74, name: "Sports Product" },
  { id: 75, name: "Sports Retail" },
  { id: 76, name: "Telecom Product" },
  { id: 77, name: "Telephone Service" },
  { id: 78, name: "Television Network" },
  { id: 79, name: "Television Network" },
  { id: 80, name: "Ticketing" },
  { id: 81, name: "Transportation" },
  { id: 82, name: "Travel" },
  { id: 83, name: "Utility" },
  { id: 84, name: "Video Game" },
  { id: 85, name: "Vision" },
  { id: 86, name: "Waste Management" },
  { id: 87, name: "Water" },
  { id: 88, name: "Website" },
  { id: 89, name: "Wine" },
  { id: 90, name: "Wireless Service" },
  { id: 91, name: "NHL Institutional" },
  { id: 92, name: "Club Institutional" },
  { id: 93, name: "National Rightsholder" },
  { id: 94, name: "International Rightsholder" },
  { id: 95, name: "Local Rightsholder" },
  { id: 96, name: "NHL Event" },
  { id: 97, name: "NHL Product" },
  { id: 98, name: "NHL Media Property" },
  { id: 99, name: "Alcoholic Beverage" },
  { id: 100, name: "Distilled Spirits" },
  { id: 101, name: "Retail" },
  { id: 102, name: "Convenience Store" },
  { id: 103, name: "Construction Materials" },
  { id: 104, name: "Burgers" },
  { id: 105, name: "Auto Insurance" },
  { id: 106, name: "Jewelry" },
  { id: 107, name: "Athletic Apparel" },
  { id: 108, name: "Ecommerce" },
  { id: 109, name: "Non-Athletic Apparel" },
  { id: 110, name: "Telecom Service" },
  { id: 111, name: "Health Insurance" },
  { id: 112, name: "Building" },
  { id: 113, name: "Commerical Product" },
  { id: 114, name: "Other" },
  { id: 115, name: "Machinery/Power Tools" },
  { id: 116, name: "Organization" },
  { id: 117, name: "Legal" },
  { id: 118, name: "Life Insurance" },
  { id: 119, name: "Pets" },
  { id: 120, name: "Accounting/Finance/Tax" },
  { id: 121, name: "Chicken" },
  { id: 122, name: "Watch" },
  { id: 123, name: "Distilled Hard Seltzer" },
  { id: 124, name: "Commercial Builder" },
  { id: 125, name: "Office Product" },
  { id: 126, name: "Cryptocurrency" },
  { id: 127, name: "Dairy/Ice Cream" },
  { id: 128, name: "Meat/Cheese" },
  { id: 129, name: "Marketing/Advertising" },
  { id: 130, name: "Coffee/Doughnuts" },
  { id: 131, name: "Product" },
  { id: 132, name: "Beauty & Grooming" },
  { id: 133, name: "Pizza" },
  { id: 134, name: "Technology" },
  { id: 135, name: "Professional Services" },
  { id: 136, name: "Alcoholic Beverage Retail" },
  { id: 137, name: "Distilled Coolers" },
  { id: 138, name: "Sandwich/Subs" },
  { id: 139, name: "SGL" },
  { id: 140, name: "Health & Fitness" },
  { id: 141, name: "Pet Care" },
  { id: 142, name: "Recreational Vehicle" },
  { id: 143, name: "Service" },
  { id: 144, name: "Office Products" },
  { id: 145, name: "SLL" },
  { id: 146, name: "SSQ" },
  { id: 147, name: "Business Consulting" },
  { id: 148, name: "Social Media" }
];

//     {id: 1, name: 'Agriculture'},
// {id: 2, name: 'Airline'},
// {id: 3, name: 'Apartments'},
// {id: 5, name: 'Apparel Athletic'},
// {id: 6, name: 'Apparel Non-Athletic'},
// {id: 4, name: 'Apparel Retail'},
// {id: 7, name: 'Armed Forces'},
// {id: 8, name: 'Auto Dealer'},
// {id: 9, name: 'Auto Manufacturer'},
// {id: 10, name: 'Auto Parts'},
// {id: 11, name: 'Auto Repair'},
// {id: 12, name: 'Auto Tires'},
// {id: 91, name: 'Banking'},
// {id: 92, name: 'Beer'},
// {id: 93, name: 'Beverage Retail'},
// {id: 94, name: 'Beverage Service'},
// {id: 95, name: 'Car Rental'},
// {id: 96, name: 'Casino'},
// {id: 97, name: 'Commercial Product'},
// {id: 98, name: 'Computer Hardware'},
// {id: 99, name: 'Computer Software'},
// {id: 100, name: 'Construction'},
// {id: 126, name: 'Consulting'},
// {id: 127, name: 'Consumer Product'},
// {id: 128, name: 'Corporate Housing'},
// {id: 129, name: 'Credit Card'},
// {id: 130, name: 'Cryptocurrency/Non-Fungible Tokens'},
// {id: 131, name: 'Daily Fantasy'},
// {id: 132, name: 'Dental Service'},
// {id: 133, name: 'Department Retail'},
// {id: 134, name: 'Dine-In Restaurant'},
// {id: 135, name: 'Distilled Beverage'},
// {id: 136, name: 'Distributor/Bottler'},
// {id: 137, name: 'Drug/Pharmaceutical Product'},
// {id: 138, name: 'Drug/Pharmaceutical Retail'},
// {id: 139, name: 'Education'},
// {id: 140, name: 'Electronics Product'},
// {id: 141, name: 'Electronics Retail'},
// {id: 142, name: 'Energy Drink'},
// {id: 143, name: 'Entertainment'},
// {id: 144, name: 'Financial Service'},
// {id: 145, name: 'Food Product'},
// {id: 146, name: 'Food Service'},
// {id: 147, name: 'Government'},
// {id: 148, name: 'Grocery Store'},
// {id: 149, name: 'Healthcare'},
// {id: 150, name: 'Home Builder'},
// {id: 151, name: 'Home Product'},
// {id: 152, name: 'Home Retail'},
// {id: 153, name: 'Home Service'},
// {id: 154, name: 'Hospital'},
// {id: 155, name: 'Hotel/Motel'},
// {id: 156, name: 'Insurance'},
// {id: 157, name: 'Internet Service Provider'},
// {id: 158, name: 'Lottery'},
// {id: 159, name: 'Mortgage'},
// {id: 160, name: 'Non-Profit'},
// {id: 161, name: 'Office Retail'},
// {id: 162, name: 'Oil & Gas'},
// {id: 163, name: 'Organization--Other'},
// {id: 164, name: 'Product--Other'},
// {id: 165, name: 'Professional Service'},
// {id: 166, name: 'Publishing'},
// {id: 167, name: 'Quick Service Restaurant'},
// {id: 168, name: 'R.V. Manufacturer'},
// {id: 169, name: 'Radio Network'},
// {id: 170, name: 'Real Estate'},
// {id: 171, name: 'Retail--Other'},
// {id: 172, name: 'Service--Other'},
// {id: 173, name: 'Shipping'},
// {id: 174, name: 'Soft Drink'},
// {id: 175, name: 'Sports Betting'},
// {id: 176, name: 'Sports Drink'},
// {id: 177, name: 'Sports Product'},
// {id: 178, name: 'Sports Retail'},
// {id: 179, name: 'Telecom Product'},
// {id: 180, name: 'Telephone Service'},
// {id: 181, name: 'Television Network'},
// {id: 182, name: 'Television Network'},
// {id: 183, name: 'Ticketing'},
// {id: 184, name: 'Transportation'},
// {id: 185, name: 'Travel'},
// {id: 186, name: 'Utility'},
// {id: 187, name: 'Video Game'},
// {id: 188, name: 'Vision'},
// {id: 189, name: 'Waste Management'},
// {id: 190, name: 'Water'},
// {id: 191, name: 'Website'},
// {id: 192, name: 'Wine'},
// {id: 193, name: 'Wireless Service'},
// ];
export const timezoneList = [
  { name: "Africa/Abidjan" },
  { name: "Africa/Accra" },
  { name: "Africa/Addis_Ababa" },
  { name: "Africa/Algiers" },
  { name: "Africa/Asmara" },
  { name: "Africa/Bamako" },
  { name: "Africa/Bissau" },
  { name: "Africa/Blantyre" },
  { name: "Africa/Brazzaville" },
  { name: "Africa/Bujumbura" },
  { name: "Africa/Cairo" },
  { name: "Africa/Casablanca" },
  { name: "Africa/Ceuta" },
  { name: "Africa/Conakry" },
  { name: "Africa/Dakar" },
  { name: "Africa/Dar_es_Salaam" },
  { name: "Africa/Djibouti" },
  { name: "Africa/Douala" },
  { name: "Africa/El_Aaiun" },
  { name: "Africa/Freetown" },
  { name: "Africa/Harare" },
  { name: "Africa/Johannesburg" },
  { name: "Africa/Juba" },
  { name: "Africa/Kampala" },
  { name: "Africa/Khartoum" },
  { name: "Africa/Kigali" },
  { name: "Africa/Kinshasa" },
  { name: "Africa/Lagos" },
  { name: "Africa/Libreville" },
  { name: "Africa/Lome" },
  { name: "Africa/Luanda" },
  { name: "Africa/Lusaka" },
  { name: "Africa/Malabo" },
  { name: "Africa/Maputo" },
  { name: "Africa/Mbabane" },
  { name: "Africa/Mogadishu" },
  { name: "Africa/Monrovia" },
  { name: "Africa/Nairobi" },
  { name: "Africa/Ndjamena" },
  { name: "Africa/Ouagadougou" },
  { name: "Africa/Porto-Novo" },
  { name: "Africa/Sao_Tome" },
  { name: "Africa/Tripoli" },
  { name: "Africa/Tunis" },
  { name: "Africa/Windhoek" },
  { name: "America/Adak" },
  { name: "America/Anchorage" },
  { name: "America/Anguilla" },
  { name: "America/Antigua" },
  { name: "America/Araguaina" },
  { name: "America/Argentina/Buenos_Aires" },
  { name: "America/Argentina/Catamarca" },
  { name: "America/Argentina/Cordoba" },
  { name: "America/Argentina/Jujuy" },
  { name: "America/Argentina/La_Rioja" },
  { name: "America/Argentina/Mendoza" },
  { name: "America/Argentina/Rio_Gallegos" },
  { name: "America/Argentina/Salta" },
  { name: "America/Argentina/San_Juan" },
  { name: "America/Argentina/San_Luis" },
  { name: "America/Argentina/Tucuman" },
  { name: "America/Argentina/Ushuaia" },
  { name: "America/Aruba" },
  { name: "America/Asuncion" },
  { name: "America/Bahia" },
  { name: "America/Bahia_Banderas" },
  { name: "America/Barbados" },
  { name: "America/Belem" },
  { name: "America/Belize" },
  { name: "America/Blanc-Sablon" },
  { name: "America/Boa_Vista" },
  { name: "America/Bogota" },
  { name: "America/Boise" },
  { name: "America/Cambridge_Bay" },
  { name: "America/Campo_Grande" },
  { name: "America/Cancun" },
  { name: "America/Caracas" },
  { name: "America/Cayenne" },
  { name: "America/Cayman" },
  { name: "America/Chicago" },
  { name: "America/Chihuahua" },
  { name: "America/Costa_Rica" },
  { name: "America/Creston" },
  { name: "America/Curacao" },
  { name: "America/Danmarkshavn" },
  { name: "America/Dawson" },
  { name: "America/Dawson_Creek" },
  { name: "America/Denver" },
  { name: "America/Detroit" },
  { name: "America/Dominica" },
  { name: "America/Edmonton" },
  { name: "America/Eirunepe" },
  { name: "America/El_Salvador" },
  { name: "America/Fortaleza" },
  { name: "America/Glace_Bay" },
  { name: "America/Godthab" },
  { name: "America/Goose_Bay" },
  { name: "America/Grand_Turk" },
  { name: "America/Grenada" },
  { name: "America/Guadeloupe" },
  { name: "America/Guatemala" },
  { name: "America/Guayaquil" },
  { name: "America/Guyana" },
  { name: "America/Halifax" },
  { name: "America/Havana" },
  { name: "America/Hermosillo" },
  { name: "America/Indiana/Indianapolis" },
  { name: "America/Indiana/Knox" },
  { name: "America/Indiana/Marengo" },
  { name: "America/Indiana/Petersburg" },
  { name: "America/Indiana/Tell_City" },
  { name: "America/Indiana/Vevay" },
  { name: "America/Indiana/Vincennes" },
  { name: "America/Indiana/Winamac" },
  { name: "America/Inuvik" },
  { name: "America/Iqaluit" },
  { name: "America/Jamaica" },
  { name: "America/Juneau" },
  { name: "America/Kentucky/Louisville" },
  { name: "America/Kentucky/Monticello" },
  { name: "America/Kralendijk" },
  { name: "America/La_Paz" },
  { name: "America/Lima" },
  { name: "America/Los_Angeles" },
  { name: "America/Lower_Princes" },
  { name: "America/Maceio" },
  { name: "America/Managua" },
  { name: "America/Manaus" },
  { name: "America/Marigot" },
  { name: "America/Martinique" },
  { name: "America/Matamoros" },
  { name: "America/Mazatlan" },
  { name: "America/Menominee" },
  { name: "America/Merida" },
  { name: "America/Metlakatla" },
  { name: "America/Mexico_City" },
  { name: "America/Miquelon" },
  { name: "America/Moncton" },
  { name: "America/Monterrey" },
  { name: "America/Montevideo" },
  { name: "America/Montreal" },
  { name: "America/Montserrat" },
  { name: "America/Nassau" },
  { name: "America/New_York" },
  { name: "America/Nipigon" },
  { name: "America/Nome" },
  { name: "America/Noronha" },
  { name: "America/North_Dakota/Beulah" },
  { name: "America/North_Dakota/Center" },
  { name: "America/North_Dakota/New_Salem" },
  { name: "America/Ojinaga" },
  { name: "America/Panama" },
  { name: "America/Paramaribo" },
  { name: "America/Phoenix" },
  { name: "America/Port-au-Prince" },
  { name: "America/Port_of_Spain" },
  { name: "America/Porto_Velho" },
  { name: "America/Puerto_Rico" },
  { name: "America/Rainy_River" },
  { name: "America/Rankin_Inlet" },
  { name: "America/Recife" },
  { name: "America/Regina" },
  { name: "America/Resolute" },
  { name: "America/Rio_Branco" },
  { name: "America/Santa_Isabel" },
  { name: "America/Santarem" },
  { name: "America/Santiago" },
  { name: "America/Santo_Domingo" },
  { name: "America/Sao_Paulo" },
  { name: "America/Scoresbysund" },
  { name: "America/Shiprock" },
  { name: "America/Sitka" },
  { name: "America/St_Barthelemy" },
  { name: "America/St_Johns" },
  { name: "America/St_Kitts" },
  { name: "America/St_Lucia" },
  { name: "America/St_Thomas" },
  { name: "America/St_Vincent" },
  { name: "America/Swift_Current" },
  { name: "America/Tegucigalpa" },
  { name: "America/Thule" },
  { name: "America/Thunder_Bay" },
  { name: "America/Tijuana" },
  { name: "America/Toronto" },
  { name: "America/Tortola" },
  { name: "America/Vancouver" },
  { name: "America/Whitehorse" },
  { name: "America/Winnipeg" },
  { name: "America/Yakutat" },
  { name: "America/Yellowknife" },
  { name: "Asia/Aden" },
  { name: "Asia/Almaty" },
  { name: "Asia/Amman" },
  { name: "Asia/Anadyr" },
  { name: "Asia/Aqtau" },
  { name: "Asia/Aqtobe" },
  { name: "Asia/Ashgabat" },
  { name: "Asia/Baghdad" },
  { name: "Asia/Bahrain" },
  { name: "Asia/Baku" },
  { name: "Asia/Bangkok" },
  { name: "Asia/Beirut" },
  { name: "Asia/Bishkek" },
  { name: "Asia/Brunei" },
  { name: "Asia/Colombo" },
  { name: "Asia/Damascus" },
  { name: "Asia/Dhaka" },
  { name: "Asia/Dili" },
  { name: "Asia/Dubai" },
  { name: "Asia/Dushanbe" },
  { name: "Asia/Gaza" },
  { name: "Asia/Harbin" },
  { name: "Asia/Hebron" },
  { name: "Asia/Ho_Chi_Minh" },
  { name: "Asia/Hong_Kong" },
  { name: "Asia/Hovd" },
  { name: "Asia/Jakarta" },
  { name: "Asia/Jayapura" },
  { name: "Asia/Jerusalem" },
  { name: "Asia/Kabul" },
  { name: "Asia/Kamchatka" },
  { name: "Asia/Karachi" },
  { name: "Asia/Kashgar" },
  { name: "Asia/Kathmandu" },
  { name: "Asia/Khandyga" },
  { name: "Asia/Kolkata" },
  { name: "Asia/Krasnoyarsk" },
  { name: "Asia/Kuala_Lumpur" },
  { name: "Asia/Kuching" },
  { name: "Asia/Kuwait" },
  { name: "Asia/Macau" },
  { name: "Asia/Magadan" },
  { name: "Asia/Makassar" },
  { name: "Asia/Manila" },
  { name: "Asia/Muscat" },
  { name: "Asia/Nicosia" },
  { name: "Asia/Novokuznetsk" },
  { name: "Asia/Novosibirsk" },
  { name: "Asia/Omsk" },
  { name: "Asia/Oral" },
  { name: "Asia/Phnom_Penh" },
  { name: "Asia/Pontianak" },
  { name: "Asia/Pyongyang" },
  { name: "Asia/Qatar" },
  { name: "Asia/Qyzylorda" },
  { name: "Asia/Rangoon" },
  { name: "Asia/Riyadh" },
  { name: "Asia/Sakhalin" },
  { name: "Asia/Samarkand" },
  { name: "Asia/Seoul" },
  { name: "Asia/Shanghai" },
  { name: "Asia/Singapore" },
  { name: "Asia/Taipei" },
  { name: "Asia/Tashkent" },
  { name: "Asia/Tbilisi" },
  { name: "Asia/Tehran" },
  { name: "Asia/Thimphu" },
  { name: "Asia/Tokyo" },
  { name: "Asia/Ulaanbaatar" },
  { name: "Asia/Urumqi" },
  { name: "Asia/Ust-Nera" },
  { name: "Asia/Vientiane" },
  { name: "Asia/Vladivostok" },
  { name: "Asia/Yekaterinburg" },
  { name: "Asia/Yerevan" },
  { name: "Atlantic/Azores" },
  { name: "Atlantic/Bermuda" },
  { name: "Atlantic/Canary" },
  { name: "Atlantic/Cape_Verde" },
  { name: "Atlantic/Faroe" },
  { name: "Atlantic/Madeira" },
  { name: "Atlantic/Reykjavik" },
  { name: "Atlantic/South_Georgia" },
  { name: "Atlantic/St_Helena" },
  { name: "Atlantic/Stanley" },
  { name: "Australia/Adelaide" },
  { name: "Australia/Brisbane" },
  { name: "Australia/Broken_Hill" },
  { name: "Australia/Currie" },
  { name: "Australia/Darwin" },
  { name: "Australia/Eucla" },
  { name: "Australia/Hobart" },
  { name: "Australia/Lindeman" },
  { name: "Australia/Lord_Howe" },
  { name: "Australia/Melbourne" },
  { name: "Australia/Perth" },
  { name: "Australia/Sydney" },
  { name: "Canada/Atlantic" },
  { name: "Canada/Central" },
  { name: "Canada/Eastern" },
  { name: "Canada/Mountain" },
  { name: "Canada/Newfoundland" },
  { name: "Canada/Pacific" },
  { name: "Europe/Amsterdam" },
  { name: "Europe/Andorra" },
  { name: "Europe/Athens" },
  { name: "Europe/Belgrade" },
  { name: "Europe/Berlin" },
  { name: "Europe/Bratislava" },
  { name: "Europe/Brussels" },
  { name: "Europe/Bucharest" },
  { name: "Europe/Budapest" },
  { name: "Europe/Busingen" },
  { name: "Europe/Chisinau" },
  { name: "Europe/Copenhagen" },
  { name: "Europe/Dublin" },
  { name: "Europe/Gibraltar" },
  { name: "Europe/Guernsey" },
  { name: "Europe/Helsinki" },
  { name: "Europe/Isle_of_Man" },
  { name: "Europe/Istanbul" },
  { name: "Europe/Jersey" },
  { name: "Europe/Kaliningrad" },
  { name: "Europe/Kiev" },
  { name: "Europe/Lisbon" },
  { name: "Europe/Ljubljana" },
  { name: "Europe/London" },
  { name: "Europe/Luxembourg" },
  { name: "Europe/Madrid" },
  { name: "Europe/Malta" },
  { name: "Europe/Mariehamn" },
  { name: "Europe/Minsk" },
  { name: "Europe/Monaco" },
  { name: "Europe/Moscow" },
  { name: "Europe/Oslo" },
  { name: "Europe/Paris" },
  { name: "Europe/Podgorica" },
  { name: "Europe/Prague" },
  { name: "Europe/Riga" },
  { name: "Europe/Rome" },
  { name: "Europe/Samara" },
  { name: "Europe/San_Marino" },
  { name: "Europe/Sarajevo" },
  { name: "Europe/Simferopol" },
  { name: "Europe/Skopje" },
  { name: "Europe/Sofia" },
  { name: "Europe/Stockholm" },
  { name: "Europe/Tallinn" },
  { name: "Europe/Tirane" },
  { name: "Europe/Uzhgorod" },
  { name: "Europe/Vaduz" },
  { name: "Europe/Vatican" },
  { name: "Europe/Vienna" },
  { name: "Europe/Vilnius" },
  { name: "Europe/Volgograd" },
  { name: "Europe/Warsaw" },
  { name: "Europe/Zagreb" },
  { name: "Europe/Zaporozhye" },
  { name: "Europe/Zurich" },
  { name: "GMT" },
  { name: "Indian/Antananarivo" },
  { name: "Indian/Chagos" },
  { name: "Indian/Christmas" },
  { name: "Indian/Cocos" },
  { name: "Indian/Comoro" },
  { name: "Indian/Kerguelen" },
  { name: "Indian/Mahe" },
  { name: "Indian/Maldives" },
  { name: "Indian/Mauritius" },
  { name: "Indian/Mayotte" },
  { name: "Indian/Reunion" },
  { name: "Pacific/Apia" },
  { name: "Pacific/Auckland" },
  { name: "Pacific/Chatham" },
  { name: "Pacific/Chuuk" },
  { name: "Pacific/Easter" },
  { name: "Pacific/Efate" },
  { name: "Pacific/Enderbury" },
  { name: "Pacific/Fakaofo" },
  { name: "Pacific/Fiji" },
  { name: "Pacific/Galapagos" },
  { name: "Pacific/Gambier" },
  { name: "Pacific/Guadalcanal" },
  { name: "Pacific/Guam" },
  { name: "Pacific/Honolulu" },
  { name: "Pacific/Palau" },
  { name: "Pacific/Pitcairn" },
  { name: "Pacific/Pohnpei" },
  { name: "Pacific/Port_Moresby" },
  { name: "Pacific/Rarotonga" },
  { name: "Pacific/Saipan" },
  { name: "Pacific/Tahiti" },
  { name: "Pacific/Tongatapu" },
  { name: "Pacific/Wake" },
  { name: "Pacific/Wallis" },
  { name: "US/Alaska" },
  { name: "US/Arizona" },
  { name: "US/Central" },
  { name: "US/Eastern" },
  { name: "US/Hawaii" },
  { name: "US/Mountain" },
  { name: "US/Pacific" },
  { name: "UTC" }
];
export const countryList = [
  { name: "Afghanistan", code: "AF" },
  { name: "Albania", code: "AL" },
  { name: "Algeria", code: "DZ" },
  { name: "American Samoa", code: "AS" },
  { name: "Andorra", code: "AD" },
  { name: "Angola", code: "AO" },
  { name: "Anguilla", code: "AI" },
  { name: "Antarctica", code: "AQ" },
  { name: "Antigua and Barbuda", code: "AG" },
  { name: "Argentina", code: "AR" },
  { name: "Armenia", code: "AM" },
  { name: "Aruba", code: "AW" },
  { name: "Australia", code: "AU" },
  { name: "Austria", code: "AT" },
  { name: "Azerbaijan", code: "AZ" },
  { name: "Bahamas", code: "BS" },
  { name: "Bahrain", code: "BH" },
  { name: "Bangladesh", code: "BD" },
  { name: "Barbados", code: "BB" },
  { name: "Belarus", code: "BY" },
  { name: "Belgium", code: "BE" },
  { name: "Belize", code: "BZ" },
  { name: "Benin", code: "BJ" },
  { name: "Bermuda", code: "BM" },
  { name: "Bhutan", code: "BT" },
  { name: "Bolivia", code: "BO" },
  { name: "Bosnia and Herzegovina", code: "BA" },
  { name: "Botswana", code: "BW" },
  { name: "Bouvet Island", code: "BV" },
  { name: "Brazil", code: "BR" },
  { name: "British Indian Ocean Territory", code: "IO" },
  { name: "Brunei Darussalam", code: "BN" },
  { name: "Bulgaria", code: "BG" },
  { name: "Burkina Faso", code: "BF" },
  { name: "Burundi", code: "BI" },
  { name: "Cambodia", code: "KH" },
  { name: "Cameroon", code: "CM" },
  { name: "Canada", code: "CA" },
  { name: "Cape Verde", code: "CV" },
  { name: "Cayman Islands", code: "KY" },
  { name: "Central African Republic", code: "CF" },
  { name: "Chad", code: "TD" },
  { name: "Chile", code: "CL" },
  { name: "China", code: "CN" },
  { name: "Christmas Island", code: "CX" },
  { name: "Cocos (Keeling) Islands", code: "CC" },
  { name: "Colombia", code: "CO" },
  { name: "Comoros", code: "KM" },
  { name: "Congo", code: "CG" },
  { name: "Congo, The Democratic Republic of the", code: "CD" },
  { name: "Cook Islands", code: "CK" },
  { name: "Costa Rica", code: "CR" },
  { name: 'Cote D"Ivoire', code: "CI" },
  { name: "Croatia", code: "HR" },
  { name: "Cuba", code: "CU" },
  { name: "Cyprus", code: "CY" },
  { name: "Czech Republic", code: "CZ" },
  { name: "Denmark", code: "DK" },
  { name: "Djibouti", code: "DJ" },
  { name: "Dominica", code: "DM" },
  { name: "Dominican Republic", code: "DO" },
  { name: "Ecuador", code: "EC" },
  { name: "Egypt", code: "EG" },
  { name: "El Salvador", code: "SV" },
  { name: "Equatorial Guinea", code: "GQ" },
  { name: "Eritrea", code: "ER" },
  { name: "Estonia", code: "EE" },
  { name: "Ethiopia", code: "ET" },
  { name: "Falkland Islands (Malvinas)", code: "FK" },
  { name: "Faroe Islands", code: "FO" },
  { name: "Fiji", code: "FJ" },
  { name: "Finland", code: "FI" },
  { name: "France", code: "FR" },
  { name: "French Guiana", code: "GF" },
  { name: "French Polynesia", code: "PF" },
  { name: "French Southern Territories", code: "TF" },
  { name: "Gabon", code: "GA" },
  { name: "Gambia", code: "GM" },
  { name: "Georgia", code: "GE" },
  { name: "Germany", code: "DE" },
  { name: "Ghana", code: "GH" },
  { name: "Gibraltar", code: "GI" },
  { name: "Greece", code: "GR" },
  { name: "Greenland", code: "GL" },
  { name: "Grenada", code: "GD" },
  { name: "Guadeloupe", code: "GP" },
  { name: "Guam", code: "GU" },
  { name: "Guatemala", code: "GT" },
  { name: "Guernsey", code: "GG" },
  { name: "Guinea", code: "GN" },
  { name: "Guinea-Bissau", code: "GW" },
  { name: "Guyana", code: "GY" },
  { name: "Haiti", code: "HT" },
  { name: "Heard Island and Mcdonald Islands", code: "HM" },
  { name: "Holy See (Vatican City State)", code: "VA" },
  { name: "Honduras", code: "HN" },
  { name: "Hong Kong", code: "HK" },
  { name: "Hungary", code: "HU" },
  { name: "Iceland", code: "IS" },
  { name: "India", code: "IN" },
  { name: "Indonesia", code: "ID" },
  { name: "Iran, Islamic Republic Of", code: "IR" },
  { name: "Iraq", code: "IQ" },
  { name: "Ireland", code: "IE" },
  { name: "Isle of Man", code: "IM" },
  { name: "Israel", code: "IL" },
  { name: "Italy", code: "IT" },
  { name: "Jamaica", code: "JM" },
  { name: "Japan", code: "JP" },
  { name: "Jersey", code: "JE" },
  { name: "Jordan", code: "JO" },
  { name: "Kazakhstan", code: "KZ" },
  { name: "Kenya", code: "KE" },
  { name: "Kiribati", code: "KI" },
  { name: 'Korea, Democratic People"S Republic of', code: "KP" },
  { name: "Korea, Republic of", code: "KR" },
  { name: "Kuwait", code: "KW" },
  { name: "Kyrgyzstan", code: "KG" },
  { name: 'Lao People"S Democratic Republic', code: "LA" },
  { name: "Latvia", code: "LV" },
  { name: "Lebanon", code: "LB" },
  { name: "Lesotho", code: "LS" },
  { name: "Liberia", code: "LR" },
  { name: "Libyan Arab Jamahiriya", code: "LY" },
  { name: "Liechtenstein", code: "LI" },
  { name: "Lithuania", code: "LT" },
  { name: "Luxembourg", code: "LU" },
  { name: "Macao", code: "MO" },
  { name: "Macedonia, The Former Yugoslav Republic of", code: "MK" },
  { name: "Madagascar", code: "MG" },
  { name: "Malawi", code: "MW" },
  { name: "Malaysia", code: "MY" },
  { name: "Maldives", code: "MV" },
  { name: "Mali", code: "ML" },
  { name: "Malta", code: "MT" },
  { name: "Marshall Islands", code: "MH" },
  { name: "Martinique", code: "MQ" },
  { name: "Mauritania", code: "MR" },
  { name: "Mauritius", code: "MU" },
  { name: "Mayotte", code: "YT" },
  { name: "Mexico", code: "MX" },
  { name: "Micronesia, Federated States of", code: "FM" },
  { name: "Moldova, Republic of", code: "MD" },
  { name: "Monaco", code: "MC" },
  { name: "Mongolia", code: "MN" },
  { name: "Montserrat", code: "MS" },
  { name: "Morocco", code: "MA" },
  { name: "Mozambique", code: "MZ" },
  { name: "Myanmar", code: "MM" },
  { name: "Namibia", code: "NA" },
  { name: "Nauru", code: "NR" },
  { name: "Nepal", code: "NP" },
  { name: "Netherlands", code: "NL" },
  { name: "Netherlands Antilles", code: "AN" },
  { name: "New Caledonia", code: "NC" },
  { name: "New Zealand", code: "NZ" },
  { name: "Nicaragua", code: "NI" },
  { name: "Niger", code: "NE" },
  { name: "Nigeria", code: "NG" },
  { name: "Niue", code: "NU" },
  { name: "Norfolk Island", code: "NF" },
  { name: "Northern Mariana Islands", code: "MP" },
  { name: "Norway", code: "NO" },
  { name: "Oman", code: "OM" },
  { name: "Pakistan", code: "PK" },
  { name: "Palau", code: "PW" },
  { name: "Palestinian Territory, Occupied", code: "PS" },
  { name: "Panama", code: "PA" },
  { name: "Papua New Guinea", code: "PG" },
  { name: "Paraguay", code: "PY" },
  { name: "Peru", code: "PE" },
  { name: "Philippines", code: "PH" },
  { name: "Pitcairn", code: "PN" },
  { name: "Poland", code: "PL" },
  { name: "Portugal", code: "PT" },
  { name: "Puerto Rico", code: "PR" },
  { name: "Qatar", code: "QA" },
  { name: "Reunion", code: "RE" },
  { name: "Romania", code: "RO" },
  { name: "Russian Federation", code: "RU" },
  { name: "RWANDA", code: "RW" },
  { name: "Saint Helena", code: "SH" },
  { name: "Saint Kitts and Nevis", code: "KN" },
  { name: "Saint Lucia", code: "LC" },
  { name: "Saint Pierre and Miquelon", code: "PM" },
  { name: "Saint Vincent and the Grenadines", code: "VC" },
  { name: "Samoa", code: "WS" },
  { name: "San Marino", code: "SM" },
  { name: "Sao Tome and Principe", code: "ST" },
  { name: "Saudi Arabia", code: "SA" },
  { name: "Senegal", code: "SN" },
  { name: "Serbia and Montenegro", code: "CS" },
  { name: "Seychelles", code: "SC" },
  { name: "Sierra Leone", code: "SL" },
  { name: "Singapore", code: "SG" },
  { name: "Slovakia", code: "SK" },
  { name: "Slovenia", code: "SI" },
  { name: "Solomon Islands", code: "SB" },
  { name: "Somalia", code: "SO" },
  { name: "South Africa", code: "ZA" },
  { name: "South Georgia and the South Sandwich Islands", code: "GS" },
  { name: "Spain", code: "ES" },
  { name: "Sri Lanka", code: "LK" },
  { name: "Sudan", code: "SD" },
  { name: "Suriname", code: "SR" },
  { name: "Svalbard and Jan Mayen", code: "SJ" },
  { name: "Swaziland", code: "SZ" },
  { name: "Sweden", code: "SE" },
  { name: "Switzerland", code: "CH" },
  { name: "Syrian Arab Republic", code: "SY" },
  { name: "Taiwan, Province of China", code: "TW" },
  { name: "Tajikistan", code: "TJ" },
  { name: "Tanzania, United Republic of", code: "TZ" },
  { name: "Thailand", code: "TH" },
  { name: "Timor-Leste", code: "TL" },
  { name: "Togo", code: "TG" },
  { name: "Tokelau", code: "TK" },
  { name: "Tonga", code: "TO" },
  { name: "Trinidad and Tobago", code: "TT" },
  { name: "Tunisia", code: "TN" },
  { name: "Turkey", code: "TR" },
  { name: "Turkmenistan", code: "TM" },
  { name: "Turks and Caicos Islands", code: "TC" },
  { name: "Tuvalu", code: "TV" },
  { name: "Uganda", code: "UG" },
  { name: "Ukraine", code: "UA" },
  { name: "United Arab Emirates", code: "AE" },
  { name: "United Kingdom", code: "GB" },
  { name: "United States", code: "US" },
  { name: "United States Minor Outlying Islands", code: "UM" },
  { name: "Uruguay", code: "UY" },
  { name: "Uzbekistan", code: "UZ" },
  { name: "Vanuatu", code: "VU" },
  { name: "Venezuela", code: "VE" },
  { name: "Viet Nam", code: "VN" },
  { name: "Virgin Islands, British", code: "VG" },
  { name: "Virgin Islands, U.S.", code: "VI" },
  { name: "Wallis and Futuna", code: "WF" },
  { name: "Western Sahara", code: "EH" },
  { name: "Yemen", code: "YE" },
  { name: "Zambia", code: "ZM" },
  { name: "Zimbabwe", code: "ZW" }
];
