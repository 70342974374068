import React, { useEffect, useState, useRef } from "react";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import FullCalendar from "@fullcalendar/react";
import listPlugin from "@fullcalendar/list";
import dayGridPlugin from "@fullcalendar/daygrid";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Divider } from "primereact/divider";
import { Calendar as PRCalendar } from "primereact/calendar";
import EventService from "../service/EventsService";
import ReactTooltip from "react-tooltip";
import { useHistory } from "react-router-dom";
import { getTimezoneOffset } from '../utilities/functions';

export const CalendarPage = (props) => {
  const [eventDialog, setEventDialog] = useState(false);
  const [clickedEvent] = useState(null);
  const [changedEvent, setChangedEvent] = useState({
    title: "",
    start: null,
    end: null,
    allDay: null
  });
  const [events, setEvents] = useState(null);

  const tooltipRef = useRef();
  const toast = useRef(null);
  const ET_OFFSET_MILLISECONDS = 
    getTimezoneOffset("America/New_York", new Date()) * 60 * 60 * 1000;
  const history = useHistory();

  const convertEvents = (events) => {
    return events.map((event) => {
      return {
        ...event,
        title: event.name,
        start: new Date(event.et_time).getTime() + ET_OFFSET_MILLISECONDS, 
        end: new Date(event.et_time).getTime() + ET_OFFSET_MILLISECONDS 
      };
    });
  };

  useEffect(() => {
    document.title = "Hub | Home";
    setBreadcrumb();
    const eventService = new EventService();
    eventService.getCalendarEvents(props.user).then((data) => {
      setEvents(convertEvents(data));
    });
  }, []);

  const setBreadcrumb = () => {
    const breadcrumbDiv = document
      .getElementsByClassName("layout-breadcrumb viewname")[0];
    const breadcrumbText = `Game Schedule`;
    breadcrumbDiv.innerHTML = breadcrumbText;
  };
  const reset = () => {
    setEventDialog(false);
  };

  const footer = (
    <>
      <Button
        type="button"
        label="Cancel"
        icon="pi pi-refresh"
        className="p-button-text"
        onClick={reset}
      />
    </>
  );

  const getTimeInEtTimezone = (origDate, timeOnly = true) => {
    if (!origDate) return "";
    let d = new Date(origDate);
    d = new Date(d.getTime() + ET_OFFSET_MILLISECONDS);

    const dateOptions = timeOnly
      ? { timeZone: "UTC", hour: "numeric", minute: "2-digit" }
      : {
          timeZone: "UTC",
          month: "2-digit",
          day: "2-digit",
          year: "numeric",
          hour: "numeric",
          minute: "2-digit"
        };

    const dateFormatter = new Intl.DateTimeFormat("en-US", dateOptions);
    const dateAsFormattedString =
      dateFormatter.format(d).replace(" A", "A").replace(" P", "P") + " ET";
    return dateAsFormattedString;
  };

  const closeTooltip = () => {
    tooltipRef.current.hideTooltip();
    document.getElementById("eventTooltipId").style.display = "none";
  };
  const openEventPage = (eventInfo) => {
    const id = eventInfo.id;
    if (props.user.club === 1)
      id && history.push({ pathname: `/event/${id}`, state: { metaInfo: eventInfo } });
  };
  const openPlaylistPage = (id) => {
    id && history.push({ pathname: `/playlist/${id}`, state: {} });
  };

  const EventTooltipComponent = (props) => {
    const id = props.id;
    const event = events.find((ev) => ev.id === parseInt(id));

    if (!event) return null;

    return (
      <>
        <div className="calendarEventTooltipHeader">
          <span onClick={() => openEventPage(event)}>{event.title}</span>
          <i
            onClick={closeTooltip}
            style={{ position: "absolute", right: 2, top: 2 }}
            className="pi pi-times"></i>
        </div>
        <div className="calendarEventTooltipBody">
          <div className="tooltipTableRow">
            <div className="tooltipTableCellLeft">Competition</div>
            <div className="tooltipTableCellRight">{event.competition}</div>
          </div>
          <div className="tooltipTableRow">
            <div className="tooltipTableCellLeft">
              <span>
                <img
                  src={
                    event.venue.country.code.toLowerCase() === "us"
                      ? "assets/demo/images/flags/us-flag.png"
                      : "assets/demo/images/flags/flag_placeholder.png"
                  }
                  alt={event.venue.region}
                  className={`flag flag-${event.venue.country.code.toLowerCase()}`}
                  width={30}
                  height={20}
                />
                <span
                  style={{ marginLeft: ".5em", verticalAlign: "middle", fontWeight: "400" }}
                  className="image-text">
                  {event.venue.city}
                </span>
              </span>
            </div>
            <div className="tooltipTableCellRight">{getTimeInEtTimezone(event.et_time, false)}</div>
          </div>

          {event.outputs.length > 0 && (
            <>
              <Divider align="center">
                <div className="inline-flex align-items-center">
                  <i className="pi pi-list mr-2"></i>&nbsp;
                  <b>Feeds</b>
                </div>
              </Divider>

              {event.outputs.map((output, i) => (
                <div
                  onClick={() => openPlaylistPage(output.id)}
                  className={
                    i % 2 === 0
                      ? "cursorPointer tooltipTableRow greyTableRow"
                      : "cursorPointer tooltipTableRow"
                  }
                  style={{ paddingLeft: 10, paddingRight: 30, color: "#1976D2" }}
                  key={i}>
                  <div className="tooltipTableCellLeft">{output.name}</div>
                </div>
              ))}
            </>
          )}
        </div>
      </>
    );
  };
  function timeFormat(info) {
    let hours = info.date.hour;
    let minutes = info.date.minute;
    const ampm = hours >= 12 ? "PM" : "AM";

    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes;
    const strTime = hours + ":" + minutes + ampm + " ET";

    return <span style={{ fontSize: "0.9rem", fontWeight: 600 }}>{strTime}</span>;
  }
  const CalendarEventTitle = (props) => {
    const isListView = props.eventInfo.view.type === "listMonth";
    const titleParts = props.eventInfo.event.title
      .replace(/\s/g, "").split("@");

    let standardName = false; // XXX @ YYY || XXX@YYY

    if (titleParts.length === 2) {
      if (titleParts[0].length === 3 && titleParts[1].length === 3) standardName = true;
    }
    return (
      <span className="calendarEventTitle">
        {standardName ? (
          <span>
            {isListView ? (
              <span>
                <span className="calendarTeamName">{titleParts[0]}</span>
                <span>&nbsp;&nbsp;@&nbsp;&nbsp;</span>
                <span className="calendarTeamName" style={{ width: 36 }}>
                  {titleParts[1]}&nbsp;&nbsp;
                </span>
              </span>
            ) : (
              <span>
                <span className="calendarTeamName">{titleParts[0]}</span>
                <span>&nbsp;@&nbsp;</span>
                <span className="calendarTeamName">{titleParts[1]}</span>
              </span>
            )}
          </span>
        ) : (
          props.eventInfo.event.title
        )}
        <span className="calendarEventLocation">
          {" "}
          {getTimeInEtTimezone(props.eventInfo.event._def.extendedProps.et_time)}
        </span>
      </span>
    );
  };
  const renderEventContent = (eventInfo) => {
    const numOfOutputs = eventInfo.event._def.extendedProps.outputs.length;

    if (!eventInfo) return null;
    return (
      <div>
        <span
          onClick={() => {
            if (eventInfo.event._def.extendedProps.outputs.length > 0)
              openPlaylistPage(eventInfo.event._def.extendedProps.outputs[0].id);
            else
              toast.current.show({
                severity: "warn",
                summary: "No playlists for this event so far",
                detail: "No playlists",
                life: 4000
              });
          }}
          className="calendarEventLink">
          <CalendarEventTitle eventInfo={eventInfo} />
        </span>
        &nbsp;
        <span
          data-event="click focus"
          data-tip={eventInfo.event.id}
          data-for="eventTooltipId"
          id={"id" + eventInfo.event.id}>
          {numOfOutputs > 0 ? (
            <i className="pi pi-external-link calendarEventTooltipIconBlue"></i>
          ) : (
            <i className="pi pi-external-link calendarEventTooltipIconGrey"></i>
          )}
        </span>
        <ReactTooltip
          ref={tooltipRef}
          id="eventTooltipId"
          globalEventOff="click"
          className="eventTooltip card"
          getContent={(eventId) => <EventTooltipComponent id={eventId} />}
          effect="solid"
          delayHide={10}
          delayUpdate={10}
          place={"top"}
          type={"light"}
        />
      </div>
    );
  }

  /*
   ******************    MAIN    **********************
   */

  return (
    <div className="p-grid">
      <div className="p-col-12">
        <div className="card card-container">
          <Toast ref={toast} />
          {events ? (
            <FullCalendar
              height={"auto"}
              timeZone="America/New York"
              initialDate={new Date("2022-10-10")}
              events={events}
              plugins={[dayGridPlugin, listPlugin]}
              listDaySideFormat={false}
              eventTimeFormat={timeFormat}
              headerToolbar={{
                left: "prev,next",
                center: "title",
                initialView: "dayGridMonth",
                right: "dayGridMonth,listMonth"
              }}
              buttonText={{ month: "Calendar", list: "List" }}
              defaultTimedEventDuration={0}
              firstDay={1}
              selectable
              fixedWeekCount={false}
              eventContent={renderEventContent}
            />
          ) : (
            <>
              <div style={{ textAlign: "center", marginTop: "25vh" }}>
                <div className="lds-ellipsis">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              </div>
            </>
          )}

          <Dialog
            visible={eventDialog && !!clickedEvent}
            style={{ width: "450px" }}
            header="Event Details"
            footer={footer}
            modal
            closable
            onHide={() => setEventDialog(false)}>
            <div className="p-fluid">
              <div className="p-field">
                <label htmlFor="title">Title</label>
                <InputText
                  id="title"
                  value={changedEvent.title}
                  onChange={(e) =>
                    setChangedEvent({ ...changedEvent, ...{ title: e.target.value } })
                  }
                  required
                  autoFocus
                />
              </div>
              <div className="p-field">
                <label htmlFor="start">Time</label>
                <PRCalendar
                  id="start"
                  value={changedEvent.start}
                  onChange={(e) => setChangedEvent({ ...changedEvent, ...{ start: e.value } })}
                  showTime
                  appendTo={document.body}
                />
              </div>
            </div>
          </Dialog>
        </div>
      </div>
    </div>
  );
};
