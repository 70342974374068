/*eslint no-unused-vars: ["error", { "argsIgnorePattern": "^_" }]*/

import {
  BACKEND_LAMBDA,
  CLUBS,
  countryList,
  EXPORT_LAMBDA,
  NETWORKS,
  CLOUDFRONT_PREFIX
} from "../utilities/constants";
import {
  appendToFilename,
  buildS3Url,
  dbLambda,
  dbLambdaNew,
  dbLambdaS3
} from "../utilities/functions";

import { Amplify, Auth } from "aws-amplify";
import config from "../aws-exports";

const AWS = require("aws-sdk");
AWS.config.update({
  region: "eu-central-1",
});

Amplify.configure(config);
Auth.configure(config);

const S3 = new AWS.S3({ signatureVersion: "v4" });

const MEDIA_BUCKET = "test-hub2-media";
const S3_PREFIX = "test-hub-2/media/event/";
//'test-hub2-media';

export default class EventService {
  async getCompData(compId) {
    let queries, comp;
    if (compId) {
      queries = await dbLambda({ hub2: true, competition: true, id: compId });
      //const eventList = await this.getEvents();
      comp = queries[0][0];
    } else {
      queries = await dbLambda({ hub2: true, competition: true });
    }
    const unsortedSports = this.sortObjectsByName(queries[1]);

    const venues = this.handleCountryCodes(this.sortObjectsByName(queries[2]));

    const allEvents = queries[3]; // this competition events and template events
    const allSegments = queries[4];
    const outputs = queries.length > 5 ? queries[5] : [];
    const zones = queries.length > 6 ? queries[6] : [];

    const sports = unsortedSports.map((sport) => {
      const sportSegmentsAndTriggers = allSegments.filter(
        (segment) => segment.sport_id === sport.id
      );
      // sort by segments and triggers. Triggers have no duration
      let segments = [],
        triggers = [];
      for (let el of sportSegmentsAndTriggers) {
        if (el.duration) segments.push(el);
        else triggers.push(el);
      }
      return { ...sport, segments, triggers };
    });

    const compEventsSegmentsAndTriggers = allSegments.filter((segment) => segment.event_id);
    const eventsAndTemplates = allEvents.map((event) => {
      const thisEventSegmentsAndTriggers = compEventsSegmentsAndTriggers.filter(
        (segment) => segment.event_id === event.id
      );
      return {
        ...event,
        venue: venues.find((venue) => venue.id === event.venueId),
        sport: sports.find((sport) => sport.id === event.sportId),
        outputs: outputs.filter((output) => output.event_id === event.id),
        segments: thisEventSegmentsAndTriggers.filter((segment) => segment.duration),
        triggers: thisEventSegmentsAndTriggers.filter((segment) => !segment.duration),
        zones: zones.filter((zone) => zone.event_id === event.id)
      };
    });
    const events = eventsAndTemplates.filter((event) => event.competitionId);
    const templates = eventsAndTemplates.filter((event) => !event.competitionId);
    if (compId) return { comp, sports, venues, events, templates };
    else return { sports, venues, events, templates };
    //eventList.filter(event => event.competitionId === compId);
  }

  async getTemplates() {
    let queries;
    queries = await dbLambda({ hub2: true, templates: true });
    const unsortedSports = this.sortObjectsByName(queries[0]);

    const venues = this.handleCountryCodes(this.sortObjectsByName(queries[1]));

    const allEvents = queries[2];
    const allSegments = queries[3];
    const outputs = queries.length > 4 ? queries[4] : [];
    const zones = queries.length > 5 ? queries[5] : [];

    const sports = unsortedSports.map((sport) => {
      const sportSegmentsAndTriggers = allSegments.filter(
        (segment) => segment.sport_id === sport.id
      );
      // sort by segments and triggers. Triggers have no duration
      let segments = [],
        triggers = [];
      for (let el of sportSegmentsAndTriggers) {
        if (el.duration) segments.push(el);
        else triggers.push(el);
      }
      return { ...sport, segments, triggers };
    });

    const compEventsSegmentsAndTriggers = allSegments.filter((segment) => segment.event_id);
    const events = allEvents.map((event) => {
      const thisEventSegmentsAndTriggers = compEventsSegmentsAndTriggers.filter(
        (segment) => segment.event_id === event.id
      );
      return {
        ...event,
        venue: venues.find((venue) => venue.id === event.venueId),
        sport: sports.find((sport) => sport.id === event.sportId),
        outputs: outputs.filter((output) => output.event_id === event.id),
        segments: thisEventSegmentsAndTriggers.filter((segment) => segment.duration),
        triggers: thisEventSegmentsAndTriggers.filter((segment) => !segment.duration),
        zones: zones.filter((zone) => zone.event_id === event.id)
      };
    });
    return { sports, venues, events };
  }

  async getCalendarEvents(user) {
    const response = await dbLambda({ hub2: true, operation: "getCalendarEvents" }, BACKEND_LAMBDA);
    const [venues, events, outputs] = response.body;
    const venuesWithCodes = this.handleCountryCodes(venues);

    let userClubId = 32;
    if (user && user.club) {
      userClubId = user.club;
    }

    const isClubUser = userClubId !== 1;

    let eventList = events;
    if (isClubUser) {
      const userClub = CLUBS.find((club) => club.id === userClubId);
      if (userClub) eventList = events.filter((event) => event.name.includes(userClub.code));
    }

    eventList = eventList.map((event) => ({
      ...event,
      venue: venuesWithCodes.find((venue) => venue.id === event.venue_id),
      outputs: outputs.filter((output) => output.event_id === event.id),
      competition: "Regular" //comps.find(comp => comp.id === event.competitionId),
    }));

    return eventList;
  }
  async getEvent(id) {
    const queries = await dbLambda({ hub2: true, event: true, id: id });
    const event = queries[0][0]; // event data
    const unsortedSports = this.sortObjectsByName(queries[1]);
    const venues = this.handleCountryCodes(this.sortObjectsByName(queries[2]));
    const outputs = queries[3].map((output) => {
      const advertisers = queries[5]
        .filter((ader) => ader.output_id === output.id)
        .map((ader) => ader.name);
      return { ...output, advertisers: advertisers.sort((a, b) => a.localeCompare(b)) };
    }); // this event outputs
    const allSegments = queries[4];
    const zones = queries[6];

    const sports = unsortedSports.map((sport) => {
      const sportSegmentsAndTriggers = allSegments.filter(
        (segment) => segment.sport_id === sport.id
      );
      // sort by segments and triggers. Triggers have no duration
      let segments = [],
        triggers = [];
      for (let el of sportSegmentsAndTriggers) {
        if (el.duration) segments.push(el);
        else triggers.push(el);
      }
      return { ...sport, segments, triggers };
    });

    event.venue = venues.find((venue) => venue.id === event.venueId);
    event.sport = sports.find((sport) => sport.id === event.sportId);
    event.segments = allSegments.filter((segment) => segment.event_id === event.id);
    event.zones = zones;

    return { event, sports, venues, outputs };
  }
  groupBy = (arr, property) => {
    return arr.reduce((acc, cur) => {
      acc[cur[property]] = [...(acc[cur[property]] || []), cur];
      return acc;
    }, {});
  };
  groupIntoSubarrays = (arr, key) => {
    return arr.reduce(function (r, a, i) {
      if (!i || r[r.length - 1][0][key] !== a[key]) {
        return r.concat([[a]]);
      }
      r[r.length - 1].push(a);
      return r;
    }, []);
  };

  //////////////////////////
  // TODO: some adverts have multiple assets (one with T as status) advert with id 73 is the only example for now
  /////////////////////////
  async getExport(id, exportType = "eventExport") {
    const lambdaResponse = await dbLambda({ hub2: true, exportType, id }, EXPORT_LAMBDA);
    return lambdaResponse;
  }

  async testQueryLambda() {
    const queries = await dbLambda({ hub2: true, testQuery: true });
    return queries;
  }
  async createSharedPlaylist(id) {
    const response = await dbLambda(
      {
        operation: "createSharedPlaylist",
        data: { id }
      },
      BACKEND_LAMBDA
    );
    return response.body;
  }
  async createSharedPlaylist_Impressions(sharedObj) {
    const response = await dbLambda(
      {
        operation: "createSharedPlaylist_Impressions",
        data: sharedObj
      },
      BACKEND_LAMBDA
    );
    return response.body;
  }

  async getPlaylist(id, user, adType) {
    const response = await dbLambda(
      {
        operation: "getPlaylist",
        data: { id, adType }
      },
      BACKEND_LAMBDA
    );
    const queries = response.body;
    let output = queries[0][0]; // output data
    // const venues = this.handleCountryCodes(this.sortObjectsByName(queries[1]));
    const event = queries[1][0];
    const allSegments = queries[2];
    let adverts = queries[3];
    const advertisers = queries[4];
    let assetList = queries[5];

    const zones = queries[6];

    const impressions = queries[7];
    const placements = queries[8];
    const approvals = queries[11];
    // sort by id (TMP)
    //
    const feedOrder = [
      "Away RSN",
      "Home RSN",
      "US National",
      "CA National",
      "CA National (FR)",
      "Away RSN (FR)",
      "Home RSN (FR)",
      "INTL 1"
    ];
    const outputs = queries[9].sort(
      (a, b) => feedOrder.indexOf(a.name) - feedOrder.indexOf(b.name)
    );

    const templates = queries[10];

    event.segments = allSegments.filter((segment) => segment.event_id === event.id);
    event.zones = zones;

    assetList = assetList.map((asset) => ({
      ...asset,
      thumbnail:
        CLOUDFRONT_PREFIX +
        asset.path +
        asset.thumbnail,
      playout:
        CLOUDFRONT_PREFIX + asset.path + asset.playout,
      preview:
        CLOUDFRONT_PREFIX + asset.path + asset.preview
    }));

    adverts = adverts.filter(
      (ad) =>
        ad.club_id === user.club ||
        ad.club_id === event.away ||
        ad.club_id === event.home ||
        ad.club_id === 1
    );
    //}

    adverts = adverts.filter((ad) => {
      const approval = approvals.find((appr) => appr.advert_id === ad.id);
      if (approval && approval.status === 1) {
        return ad;
      }
    });
    // ************************************************************

    adverts = adverts
      .map((advert) => ({
        ...advert,
        advertiser: advertisers.find((advertiser) => advertiser.id === advert.advertiser_id),
        assets: assetList.filter((asset) => asset.advert_id === advert.id)
      }))
      .sort((a, b) => a.advertiser.name.localeCompare(b.advertiser.name));

    adverts = adverts.filter((advert) => advert.assets.length > 0);

    const allocatedAdverts = adverts.filter((ad) => ad.club_id === user.club && ad.active);

    // ***********************************
    // current output networks
    output.networks = [];
    switch (output.name) {
      case "Away RSN":
        if (event.away_networks) {
          output.networks = event.away_networks.split(",");
        }
        break;
      case "Home RSN":
        if (event.home_networks) {
          output.networks = event.home_networks.split(",");
        }
        break;
      case "US National":
        if (event.national_networks) {
          let networks = event.national_networks.split(",");
          let US_networks = NETWORKS.filter((network) => network.isUS).map(
            (network) => network.code
          );
          networks = networks.filter((network) => US_networks.includes(network));
          output.networks = networks;
        }
        break;
      case "CA National":
      case "CA National (FR)":
        if (event.national_networks) {
          let networks = event.national_networks.split(",");
          let CA_networks = NETWORKS.filter((network) => !network.isUS).map(
            (network) => network.code
          );
          networks = networks.filter((network) => CA_networks.includes(network));
          output.networks = networks;
        }
        break;

      default:
        output.networks = [];
    }
    const sports = [];
    return {
      event,
      sports,
      output,
      allocatedAdverts,
      adverts,
      advertisers,
      placements,
      impressions,
      outputs,
      templates
    };
  }

  async getOutput(id, user) {
    const response = await dbLambda(
      {
        operation: "getOutput",
        data: { id }
      },
      BACKEND_LAMBDA
    );
    const queries = response.body;
    let output = queries[0][0]; // output data
    const venues = this.handleCountryCodes(this.sortObjectsByName(queries[1]));
    const event = queries[2][0];
    const allSegments = queries[3];
    let adverts = queries[4];
    const advertisers = queries[5];
    let assetList = queries[6];

    const zones = queries[7];

    const impressions = queries[8];
    const placements = queries[9];
    const approvals = queries[12];
    const feedOrder = [
      "Away RSN",
      "Home RSN",
      "US National",
      "CA National",
      "CA National (FR)",
      "Away RSN (FR)",
      "Home RSN (FR)",
      "INTL 1"
    ];
    const outputs = queries[10].sort(
      (a, b) => feedOrder.indexOf(a.name) - feedOrder.indexOf(b.name)
    );

    const templates = queries[11];

    event.venue = venues.find((venue) => venue.id === event.venueId);
    // event.sport = sports.find(sport => sport.id === event.sportId);
    event.segments = allSegments.filter((segment) => segment.event_id === event.id);
    event.zones = zones;

    assetList = assetList.map((asset) => ({
      ...asset,
      thumbnail:
        CLOUDFRONT_PREFIX +
        asset.path +
        asset.thumbnail,
      playout:
        CLOUDFRONT_PREFIX + asset.path + asset.playout,
      preview:
        CLOUDFRONT_PREFIX + asset.path + asset.preview
    }));

    adverts = adverts.filter(
      (ad) =>
        ad.club_id === user.club ||
        ad.club_id === event.away ||
        ad.club_id === event.home ||
        ad.club_id === 1
    );

    adverts = adverts.filter((ad) => {
      const approval = approvals.find((appr) => appr.advert_id === ad.id);
      //
      if (approval && approval.status === 1) {
        return ad;
      }
    });
    // ************************************************************

    adverts = adverts
      .map((advert) => ({
        ...advert,
        advertiser: advertisers.find((advertiser) => advertiser.id === advert.advertiser_id),
        assets: assetList.filter((asset) => asset.advert_id === advert.id)
      }))
      .sort((a, b) => a.advertiser.name.localeCompare(b.advertiser.name));

    adverts = adverts.filter((advert) => advert.assets.length > 0);

    const allocatedAdverts = adverts.filter((ad) => ad.club_id === user.club);
    // queries[5].map(advert => ({ ...advert,
    //     advertiser: advertisers.find(advertiser => advertiser.id === advert.advertiser_id),
    //     assets: assetList.filter(asset => asset.advert_id === advert.id)
    // })).sort((a, b) => a.advertiser.name.localeCompare(b.advertiser.name));

    // ***********************************
    // current output networks
    output.networks = [];
    switch (output.name) {
      case "Away RSN":
        if (event.away_networks) {
          output.networks = event.away_networks.split(",");
        }
        break;
      case "Home RSN":
        if (event.home_networks) {
          output.networks = event.home_networks.split(",");
        }
        break;
      case "US National":
        if (event.national_networks) {
          let networks = event.national_networks.split(",");
          let US_networks = NETWORKS.filter((network) => network.isUS).map(
            (network) => network.code
          );
          networks = networks.filter((network) => US_networks.includes(network));
          output.networks = networks;
        }
        break;
      case "CA National":
      case "CA National (FR)":
        if (event.national_networks) {
          let networks = event.national_networks.split(",");
          let CA_networks = NETWORKS.filter((network) => !network.isUS).map(
            (network) => network.code
          );
          networks = networks.filter((network) => CA_networks.includes(network));
          output.networks = networks;
        }
        break;

      default:
        output.networks = [];
    }
    const sports = [];
    return {
      event,
      sports,
      venues,
      output,
      allocatedAdverts,
      adverts,
      advertisers,
      placements,
      impressions,
      outputs,
      templates
    };
  }

  getTimeOrDateInEtTimezone = (origDate, timeOnly = true) => {
    if (!origDate) {
      return "";
    }

    let d = new Date(origDate);
    // -10800000 is a timezoneOffset
    d = new Date(d.getTime() - 14400000); // - (-10800000));

    const dateOptions = timeOnly
      ? { timeZone: "UTC", hour: "numeric", minute: "2-digit" }
      : { timeZone: "UTC", month: "2-digit", day: "2-digit", year: "numeric" };

    const dateFormatter = new Intl.DateTimeFormat("en-US", dateOptions);
    const dateAsFormattedString = dateFormatter.format(d).replace(" AM", "am").replace(" PM", "pm");
    return timeOnly ? dateAsFormattedString + " ET" : dateAsFormattedString;
  };

  async getStats(user) {
    const response = await dbLambda(
      {
        operation: "getStats",
        data: { clubId: user.club }
        ////////////////////////////////
        ///////////////////////////////
        // TODO: use BACKEND_LAMBDA
      },
      "hub2-api-user-get"
    );
    ///////////////////////////////
    ///////////////////////////////
    const { outputs, events } = response.body;

    let availableEvents = [];
    events.map((event) => {
      const eventOutputs = outputs.filter((output) => output.event_id === event.id);
      if (eventOutputs.length > 0) {
        const outputsWithNetworks = eventOutputs.map((output) => {
          let network = "";
          if (output.name === "Home RSN" || output.name === "Home RSN (FR)") {
            network = event.home_networks;
          } else if (output.name === "Away RSN" || output.name === "Away RSN (FR)") {
            network = event.away_networks;
          }

          // TODO: ensure National and INTL are different
          else {
            network = event.national_networks;
          }

          return {
            ...output,
            network
          };
        });

        const clubAway = CLUBS.find((club) => club.id === event.away);
        const clubHome = CLUBS.find((club) => club.id === event.home);
        const away = clubAway ? clubAway.code : "";
        const home = clubAway ? clubHome.code : "";

        const eventDate = this.getTimeOrDateInEtTimezone(event.et_time, false);
        const eventTime = this.getTimeOrDateInEtTimezone(event.et_time);

        availableEvents.push({
          ...event,
          name: `${away} @ ${home}`,
          date: eventDate,
          time: eventTime,
          outputs: outputsWithNetworks
        });
      }
    });
    return availableEvents;
  }

  async getSVOutput(id, user) {
    const response = await dbLambda(
      {
        operation: "getSVOutput",
        data: { id }
      },
      BACKEND_LAMBDA
    );
    const queries = response.body;
    let output = queries[0][0]; // output data
    const venues = this.handleCountryCodes(this.sortObjectsByName(queries[1]));
    const event = queries[2][0];
    const allSegments = queries[3];
    let adverts = queries[4];
    const advertisers = queries[5];
    let assetList = queries[6];

    const zones = queries[7];

    const impressions = queries[8];
    const placements = queries[9];
    const approvals = queries[12];

    const feedOrder = [
      "Away RSN",
      "Home RSN",
      "US National",
      "CA National",
      "CA National (FR)",
      "Away RSN (FR)",
      "Home RSN (FR)",
      "INTL 1"
    ];
    const outputs = queries[10].sort(
      (a, b) => feedOrder.indexOf(a.name) - feedOrder.indexOf(b.name)
    );

    const templates = queries[11];

    event.venue = venues.find((venue) => venue.id === event.venueId);
    // event.sport = sports.find(sport => sport.id === event.sportId);
    event.segments = allSegments.filter((segment) => segment.event_id === event.id);
    event.zones = zones;

    assetList = assetList.map((asset) => ({
      ...asset,
      thumbnail:
        CLOUDFRONT_PREFIX +
        asset.path +
        asset.thumbnail,
      playout:
        CLOUDFRONT_PREFIX + asset.path + asset.playout,
      preview:
        CLOUDFRONT_PREFIX + asset.path + asset.preview
    }));

    adverts = adverts.filter(
      (ad) =>
        ad.club_id === user.club ||
        ad.club_id === event.away ||
        ad.club_id === event.home ||
        ad.club_id === 1
    );

    adverts = adverts.filter((ad) => {
      const approval = approvals.find((appr) => appr.advert_id === ad.id);
      if (approval && approval.status === 1) {
        return ad;
      }
    });
    // ************************************************************

    adverts = adverts
      .map((advert) => ({
        ...advert,
        advertiser: advertisers.find((advertiser) => advertiser.id === advert.advertiser_id),
        assets: assetList.filter((asset) => asset.advert_id === advert.id)
      }))
      .sort((a, b) => a.advertiser.name.localeCompare(b.advertiser.name));

    adverts = adverts.filter((advert) => advert.assets.length > 0);

    const allocatedAdverts = adverts.filter((ad) => ad.club_id === user.club);

    output.networks = [];

    switch (output.name) {
      case "Away RSN":
        if (event.away_networks) {
          output.networks = event.away_networks.split(",");
        }
        break;
      case "Home RSN":
        if (event.home_networks) {
          output.networks = event.home_networks.split(",");
        }
        break;
      case "US National":
        if (event.national_networks) {
          let networks = event.national_networks.split(",");
          let US_networks = NETWORKS.filter((network) => network.isUS).map(
            (network) => network.code
          );
          networks = networks.filter((network) => US_networks.includes(network));
          output.networks = networks;
        }
        break;
      case "CA National":
      case "CA National (FR)":
        if (event.national_networks) {
          let networks = event.national_networks.split(",");
          let CA_networks = NETWORKS.filter((network) => !network.isUS).map(
            (network) => network.code
          );
          networks = networks.filter((network) => CA_networks.includes(network));
          output.networks = networks;
        }
        break;

      default:
        output.networks = [];
    }

    const sports = [];
    return {
      event,
      sports,
      venues,
      output,
      allocatedAdverts,
      adverts,
      advertisers,
      placements,
      impressions,
      outputs,
      templates
    };
  }

  async createAllocations(output_id, ad_ids) {
    await dbLambda({
      hub2: true,
      create: true,
      operation: "createAllocations",
      output_id: output_id,
      ad_ids: ad_ids
    });
  }
  async deleteAllocation(output_id, advert_id) {
    const response = await dbLambda(
      {
        operation: "deleteAllocation",
        data: { output_id, advert_id }
      },
      "hub2-api-venue-post"
    );
    return response;
  }

  async createEvent(event) {
    let imageURL = "";
    if (event.image) {
      const now = new Date();
      const fileKey = appendToFilename(S3_PREFIX + event.image.name, "_" + now.getTime());
      await this.uploadImage(event.image, fileKey);
      imageURL = buildS3Url(MEDIA_BUCKET, fileKey);
    }
    const segments = event.segments.map((segment) => {
      return {
        sequence: segment.sequence,
        name: segment.name,
        duration: segment.duration
      };
    });
    const triggers = event.triggers.map((segment) => {
      return {
        sequence: segment.sequence,
        name: segment.name
      };
    });
    const input = {
      // id: event.id,
      name: event.name,
      date_time: event.date_time,
      sportId: event.sport.id,
      venueId: event.venue.id,
      // competitionId: event.competitionId ? event.competitionId : null,
      // competition: event.competitionId, // TODO: remove this from DB schema
      visible: event.visible,
      segments,
      triggers,
      zones: event.zones,
      // conditionally add optional properties
      ...(event.image && { image: imageURL }),
      ...(event.competitionId && { competitionId: event.competitionId }),
      // ...event.competitionId && { competition: event.competitionId },
      ...(event.duration && { duration: event.duration }),
      ...(event.ad_threshold && { ad_threshold: event.ad_threshold })
    };

    const result = await dbLambdaNew({ operation: "createEvent", data: input });

    return { id: result.body.id, imageURL };
  }

  async updateEvent(event, oldEvent) {
    let imageUpdated = false;
    let imageURL = null;
    //
    // image uploaded
    if (event.image && event.image.name) {
      imageUpdated = true;
      const now = new Date();
      const fileKey = appendToFilename(S3_PREFIX + event.image.name, "_" + now.getTime());
      // upload image
      await this.uploadImage(event.image, fileKey);
      imageURL = buildS3Url(MEDIA_BUCKET, fileKey);
    }
    // image removed
    else if (!event.image && oldEvent.image) {
      imageUpdated = true;
      imageURL = "";
    } else {
      imageURL = event.image;
    }

    const segments = event.sport.segments.map((segment) => {
      return {
        sequence: segment.sequence,
        name: segment.name,
        event_id: event.id,
        duration: segment.duration
      };
    });
    const triggers = event.sport.triggers.map((segment) => {
      return {
        sequence: segment.sequence,
        name: segment.name,
        event_id: event.id
      };
    });
    const input = {
      id: event.id,
      name: event.name,
      date_time: event.date_time,
      sportId: event.sport.id,
      venueId: event.venue.id,
      visible: event.visible,
      segments,
      triggers,
      zones: event.zones,
      // conditionally add optional properties
      ...(event.competitionId && { competitionId: event.competitionId }),
      ...(imageUpdated && { image: imageURL }),
      ...(event.duration && { duration: event.duration }),
      ...(event.ad_threshold && { ad_threshold: event.ad_threshold })
    };

    await dbLambda({
      hub2: true,
      update: true,
      operation: "updateEvent",
      data: input
    });

    return { imageURL };
  }

  async savePlaylistNew(imps, output_id) {
    const placements = [];
    const impressionIds = [];

    imps.map((impression) => {
      // skip empty (header) rows
      if (impression.id) {
        impressionIds.push(impression.id);

        impression.placements.map((cellPlacements, placementIndex) => {
          if (cellPlacements[0].id) {
            placements.push({
              advert_id: cellPlacements[0].id,
              zone_sequence: placementIndex + 1,
              impression_id: impression.id,
              sequence: 1
            });
          }
          if (cellPlacements[1].id) {
            placements.push({
              advert_id: cellPlacements[1].id,
              zone_sequence: placementIndex + 1,
              impression_id: impression.id,
              sequence: 2
            });
          }
        });
      }
    });
    await dbLambda(
      {
        operation: "savePlaylist",
        data: { output_id, placements }
      },
      BACKEND_LAMBDA
    );
  }
  async saveSVPlaylistNew(imps, output_id) {
    const placements = [];
    const impressionIds = [];
    const DED_ZONES_NUMBER = 5;
    imps.map((impression) => {
      // skip empty (header) rows
      if (impression.id) {
        impressionIds.push(impression.id);

        impression.placements.map((cellPlacements, placementIndex) => {
          if (cellPlacements[0].id) {
            placements.push({
              advert_id: cellPlacements[0].id,
              zone_sequence: placementIndex + 1 + DED_ZONES_NUMBER,
              impression_id: impression.id,
              sequence: 1
            });
          }
          if (cellPlacements[1].id) {
            placements.push({
              advert_id: cellPlacements[1].id,
              zone_sequence: placementIndex + 1 + DED_ZONES_NUMBER,
              impression_id: impression.id,
              sequence: 2
            });
          }
        });
      }
    });
    await dbLambda(
      {
        operation: "saveSVPlaylist",
        data: { output_id, placements }
      },
      BACKEND_LAMBDA
    );
  }

  async savePlaylist(impressions, _data, output_id, _adArray) {
    if (impressions.length === 0) {
      return;
    }

    // first, remove previous intervals and placements related to this output
    await dbLambda({
      hub2: true,
      create: true,
      operation: "removeImpressions",
      output_id: output_id
    });

    // now, create impressions only
    const createImpressionsResult = await dbLambda({
      hub2: true,
      create: true,
      operation: "createImpressions",
      impressions: impressions,
      output_id: output_id
    });

    const firstInsertedId = createImpressionsResult.insertId;

    const placements = [];

    impressions.map((impression, imp_no) => {
      const impressionPlacements = impression.placements.map((placement) => ({
        ...placement,
        impression_id: imp_no + firstInsertedId
      }));
      placements.push(...impressionPlacements);
    });

    if (placements.length > 0) {
      await dbLambda({
        hub2: true,
        create: true,
        operation: "createPlacements",
        placements: placements
      });
    }
  }
  async saveExportToDb(event_id) {
    await dbLambda(
      {
        hub2: true,
        exportType: "eventExport",
        id: event_id,
        name: "saveExportToDb"
      },
      EXPORT_LAMBDA
    );
  }

  async createOutput(output) {
    const defaultDuration = 30;

    const input = {
      name: output.name,
      event_id: output.event_id,
      set_duration: defaultDuration
    };

    const response = await dbLambda(
      { hub2: true, operation: "createOutput", data: input },
      BACKEND_LAMBDA
    );
    return response.body;
  }

  async exportOutput(event, output, adverts, advertisers, impressions, placements) {
    await dbLambdaS3({
      operation: "exportOutput",
      event: event,
      output: output,
      adverts: adverts,
      advertisers: advertisers,
      impressions: impressions,
      placements: placements
    });
  }

  async uploadImage(image, fileKey) {
    var params = {
      Bucket: MEDIA_BUCKET,
      Key: fileKey,
      ContentType: image.type,
      Body: image
    };

    await S3.upload(params).promise();
  }
  async deleteEvent(event) {
    const result = await dbLambdaNew({ operation: "deleteEvent", id: event.id });
    return result;
  }
  handleCountryCodes = (venues) => {
    if (!venues) return [];
    const r = venues.map((venue) => ({
      ...venue,
      country: countryList.find((obj) => {
        return obj.name === venue.region;
      })
    }));
    return r;
  };
  sortObjectsByName(arr) {
    arr.sort(function (a, b) {
      var textA = a.name.toUpperCase();
      var textB = b.name.toUpperCase();
      return textA < textB ? -1 : textA > textB ? 1 : 0;
    });
    return arr;
  }
}
