/*eslint no-unused-vars: ["error", { "argsIgnorePattern": "^_" }]*/
import { Auth } from 'aws-amplify';
import { CLUBS, NETWORKS } from "../utilities/constants";
const AWS = require("aws-sdk");

AWS.config.update({
  region: "eu-central-1",
});

export function buildS3Url(bucket, key) {
  return "https://" + bucket + ".s3.eu-central-1.amazonaws.com/" + key;
}
export const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) return "0 Bytes";
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB"];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
};
export const sortObjectsByName = (arr) => {
  arr.sort(function (a, b) {
    var textA = a.name.toUpperCase();
    var textB = b.name.toUpperCase();
    return textA < textB ? -1 : textA > textB ? 1 : 0;
  });
  return arr;
};
// copy string clipboard and show a toast notification
export const copyToClipboard = (toast_, str, message) => {
  navigator.clipboard.writeText(str);
  toast_.current.show({ severity: "success", summary: "Copied", detail: message, life: 500 });
};
// make sure name property is unique for each element (segments, zones)
export const isNameUnique = (items) => {
  var names = items.map((item) => item.name.trim());
  return !names.some(function (v) {
    return (
      names.filter(function (w) {
        return w === v;
      }).length > 1
    );
  });
};

// build approval/reject email
export const buildApprovalEmail = (email, isRejected, comments, url) => {
  const emailObject = !isRejected
    ? {
        subject: "Submitted NHL Artwork has been Approved",
        body: `<div style="font-size: 12px;"><p>Your submitted creative (linked below) has been approved and is ready to be scheduled.</p>
<br/>
<p>${comments}</p>
<br/>
<a href=${url}>${url}</a>
<br/>
<p>Please reach out to your NHL contact should you have any questions.</p>         
<br/>
Thank You,
<br/>
NHL Team</div>`,
        email: [email]
      }
    : {
        subject: "Submitted NHL Artwork has been Rejected",

        body: `<p>Your submitted creative (linked below) has been rejected for the following reason(s):
<p>${comments}<p>
<br/>            
<a href=${url}>${url}</a>
<br/>            
<p>Please reach out to your NHL contact should you have any questions. Otherwise, please resubmit with the edits suggested above for additional consideration.</p>            
<br/>
Thank You,
<br/>
NHL Team`,
        email: [email]
      };

  return emailObject;
};

// Returns the hour difference between the specified 
// timezone and UTC. E.g. for "America/New_York" the 
// result would be -4 or -5 hours, depending on DST 
export const getTimezoneOffset = (tz, hereDate) => {
  hereDate = new Date(hereDate || Date.now());
  hereDate.setMilliseconds(0); // rounding

  const
     hereOffsetHrs = hereDate.getTimezoneOffset() / 60 * -1,
     thereLocaleStr = hereDate.toLocaleString('en-US', {
        timeZone: tz
     }),
     thereDate = new Date(thereLocaleStr),
     diffHrs = (thereDate.getTime() - hereDate.getTime()) / 1000 / 60 / 60,
     thereOffsetHrs = hereOffsetHrs + diffHrs;

  return thereOffsetHrs;
}

// TODO: remove DateFormatter.formatDate from code throughout the app,
// use DateFormatter.formatUTCDate instead!
// getUTCDate has getUTCDate, getUTCMonth... instead of getDate and so on
export const DateFormatter = {
  monthNames: [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
  ],
  dayNames: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
  formatUTCDate: function (date, format) {
    var self = this;
    format = self.getProperDigits(format, /d+/gi, date.getUTCDate());
    format = self.getProperDigits(format, /M+/g, date.getUTCMonth() + 1);
    format = format.replace(/y+/gi, function (y) {
      var len = y.length;
      var year = date.getFullYear();
      if (len === 2) return (year + "").slice(-2);
      else if (len === 4) return year;
      return y;
    });
    format = self.getProperDigits(format, /H+/g, date.getUTCHours());
    format = self.getProperDigits(format, /h+/g, self.getHours12(date.getUTCHours()));
    format = self.getProperDigits(format, /m+/g, date.getUTCMinutes());
    format = self.getProperDigits(format, /s+/gi, date.getSeconds());
    format = format.replace(/a/gi, function (a) {
      var amPm = self.getAmPm(date.getHours());
      if (a === "A") return amPm.toUpperCase();
      return amPm;
    });
    format = self.getFullOr3Letters(format, /d+/gi, self.dayNames, date.getUTCDay());
    format = self.getFullOr3Letters(format, /M+/g, self.monthNames, date.getUTCMonth());
    return format;
  },
  formatDate: function (date, format) {
    var self = this;
    format = self.getProperDigits(format, /d+/gi, date.getDate());
    format = self.getProperDigits(format, /M+/g, date.getMonth() + 1);
    format = format.replace(/y+/gi, function (y) {
      var len = y.length;
      var year = date.getFullYear();
      if (len === 2) return (year + "").slice(-2);
      else if (len === 4) return year;
      return y;
    });
    format = self.getProperDigits(format, /H+/g, date.getHours());
    format = self.getProperDigits(format, /h+/g, self.getHours12(date.getHours()));
    format = self.getProperDigits(format, /m+/g, date.getMinutes());
    format = self.getProperDigits(format, /s+/gi, date.getSeconds());
    format = format.replace(/a/gi, function (a) {
      var amPm = self.getAmPm(date.getHours());
      if (a === "A") return amPm.toUpperCase();
      return amPm;
    });
    format = self.getFullOr3Letters(format, /d+/gi, self.dayNames, date.getDay());
    format = self.getFullOr3Letters(format, /M+/g, self.monthNames, date.getMonth());
    return format;
  },
  getProperDigits: function (format, regex, value) {
    return format.replace(regex, function (m) {
      var length = m.length;
      if (length === 1) return value;
      else if (length === 2) return ("0" + value).slice(-2);
      return m;
    });
  },
  getHours12: function (hours) {
    // https://stackoverflow.com/questions/10556879/changing-the-1-24-hour-to-1-12-hour-for-the-gethours-method
    return (hours + 24) % 12 || 12;
  },
  getAmPm: function (hours) {
    // https://stackoverflow.com/questions/8888491/how-do-you-display-javascript-datetime-in-12-hour-am-pm-format
    return hours >= 12 ? "pm" : "am";
  },
  getFullOr3Letters: function (format, regex, nameArray, value) {
    return format.replace(regex, function (s) {
      var len = s.length;
      if (len === 3) return nameArray[value].substr(0, 3);
      else if (len === 4) return nameArray[value];
      return s;
    });
  }
};
export const getZoneArray = (zones, inverse_AB_for_Zones_4_5 = false) => {
  let code = [1, 5];

  if (inverse_AB_for_Zones_4_5) {
    switch (zones) {
      case "1, 2, 4, 5":
        code = [1, 2, 4, 5];
        break;
      case "1-2 and/or 4-5":
        code = [1, 2, 4, 5];
        break;
      case "1, 2, 3, 4, 5":
        code = [1, 2, 3, 4, 5];
        break;
      case "1 & 5":
        code = [1, 5];
        break;
      case "1 and/or 5":
        code = [1, 5];
        break;
      case "1-5":
        code = [1, 2, 3, 4, 5];
        break;
      case "1, 2":
        code = [1, 2];
        break;
      case "4, 5":
        code = [4, 5];
        break;
      case "2 & 4":
        code = [2, 4];
        break;
      case "2 and/or 4":
        code = [2, 4];
        break;
      case "1":
        code = [1];
        break;
      case "2":
        code = [2];
        break;
      case "3":
        code = [3];
        break;
      case "4":
        code = [4];
        break;
      case "5":
        code = [5];
        break;
      case "1A":
        code = ["1A"];
        break;
      case "2A":
        code = ["2A"];
        break;
      case "3A":
        code = ["3A"];
        break;
      case "4A":
        code = ["4B"];
        break;
      case "5A":
        code = ["5B"];
        break;
      case "1B":
        code = ["1B"];
        break;
      case "2B":
        code = ["2B"];
        break;
      case "3B":
        code = ["3B"];
        break;
      case "4B":
        code = ["4A"];
        break;
      case "5B":
        code = ["5A"];
        break;
      case "1A & 5A":
        code = ["1A", "5B"];
        break;
      case "1A and/or 5A":
        code = ["1A", "5B"];
        break;
      case "1B & 5B":
        code = ["1B", "5A"];
        break;
      case "1B and/or 5B":
        code = ["1B", "5A"];
        break;
      case "2A & 4A":
        code = ["2A", "4B"];
        break;
      case "2B & 4B":
        code = ["2B", "4A"];
        break;
      case "2A, 2B, 4A, 4B":
        code = ["2A", "2B", "4B", "4A"];
        break;
      case "2A, 2B, 4A and/or 4B":
        code = ["2A", "2B", "4B", "4A"];
        break;
      case "3A & 3B":
        code = ["3A", "3B"];
        break;
      case "3A and/or 3B":
        code = ["3A", "3B"];
        break;
      default:
        code = [1, 5];
        break;
    }
  } else {
    switch (zones) {
      case "1, 2, 4, 5":
        code = [1, 2, 4, 5];
        break;
      case "1-2 and/or 4-5":
        code = [1, 2, 4, 5];
        break;
      case "1, 2, 3, 4, 5":
        code = [1, 2, 3, 4, 5];
        break;
      case "1 & 5":
        code = [1, 5];
        break;
      case "1 and/or 5":
        code = [1, 5];
        break;
      case "1-5":
        code = [1, 2, 3, 4, 5];
        break;
      case "1, 2":
        code = [1, 2];
        break;
      case "4, 5":
        code = [4, 5];
        break;
      case "2 & 4":
        code = [2, 4];
        break;
      case "2 and/or 4":
        code = [2, 4];
        break;
      case "1":
        code = [1];
        break;
      case "2":
        code = [2];
        break;
      case "3":
        code = [3];
        break;
      case "4":
        code = [4];
        break;
      case "5":
        code = [5];
        break;
      case "1A":
        code = ["1A"];
        break;
      case "2A":
        code = ["2A"];
        break;
      case "3A":
        code = ["3A"];
        break;
      case "4A":
        code = ["4A"];
        break;
      case "5A":
        code = ["5A"];
        break;
      case "1B":
        code = ["1B"];
        break;
      case "2B":
        code = ["2B"];
        break;
      case "3B":
        code = ["3B"];
        break;
      case "4B":
        code = ["4B"];
        break;
      case "5B":
        code = ["5B"];
        break;
      case "1A & 5A":
        code = ["1A", "5A"];
        break;
      case "1A and/or 5A":
        code = ["1A", "5A"];
        break;
      case "1B & 5B":
        code = ["1B", "5B"];
        break;
      case "1B and/or 5B":
        code = ["1B", "5B"];
        break;
      case "2A & 4A":
        code = ["2A", "4A"];
        break;
      case "2B & 4B":
        code = ["2B", "4B"];
        break;
      case "2A, 2B, 4A, 4B":
        code = ["2A", "2B", "4A", "4B"];
        break;
      case "2A, 2B, 4A and/or 4B":
        code = ["2A", "2B", "4A", "4B"];
        break;
      case "3A & 3B":
        code = ["3A", "3B"];
        break;
      case "3A and/or 3B":
        code = ["3A", "3B"];
        break;
      default:
        code = [1, 5];
        break;
    }
  }
  return code;
};
export const getOutputShareModel = (homeClubId, awayClubId, outputName) => {
  const home = CLUBS.find((club) => club.id === homeClubId);
  const away = CLUBS.find((club) => club.id === awayClubId);

  if (home && away) {
    const crossBorder = home.country !== away.country;

    if (outputName === "Home RSN" || outputName === "Home RSN (FR)") {
      return "RS-H-1";
    }

    if (outputName === "Away RSN" || outputName === "Away RSN (FR)") {
      //
      //
      if (!crossBorder && (home.share === "max" || away.share === "max")) {
        return "RS-A-2";
      }

      if (
        !crossBorder &&
        home.share !== "max" &&
        away.share !== "max" &&
        (home.share === "min" || away.share === "min")
      ) {
        return "RS-A-3";
      }

      if (crossBorder && ((home.share !== "no" && away.share !== "no") || !away.opt_out)) {
        return "RS-A-4";
      }

      // (home.share === 'no' && away.share === 'no') ||
      // (crossBorder && away.opt_out)
      return "RS-A-1";
    }

    if (outputName === "US National") {
      if (home.country === "US") {
        return "RS-USA-N-1";
      }

      if (
        (away.country === "US" && away.share === "no") ||
        (home.country === "CA" && away.opt_out)
      ) {
        return "RS-USA-N-2";
      }

      return "RS-USA-N-3";
    }

    if (outputName === "CA National" || outputName === "CA National (FR)") {
      if (home.country === "CA") {
        return "RS-CDN-N-4";
      }
      if (
        (away.country === "CA" && away.share === "no") ||
        (home.country === "US" && away.opt_out)
      ) {
        return "RS-CDN-N-5";
      }

      return "RS-CDN-N-6";
    }

    return "RS-INT-1";
  }
};

export const getOutputNetworks = (event, outputName) => {
  let result = [];

  switch (outputName) {
    case "Away RSN":
      if (event.away_networks) {
        const networks = event.away_networks.split(",");

        const nonNational_En = NETWORKS.filter(
          (network) => !network.isNational && network.isEnglish
        ).map((network) => network.code);

        result = networks.filter((network) => nonNational_En.includes(network));
      }
      break;

    case "Home RSN":
      if (event.home_networks) {
        const networks = event.home_networks.split(",");

        const nonNational_En = NETWORKS.filter(
          (network) => !network.isNational && network.isEnglish
        ).map((network) => network.code);

        result = networks.filter((network) => nonNational_En.includes(network));
      }
      break;

    case "Away RSN (FR)":
      if (event.away_networks) {
        const networks = event.away_networks.split(",");

        const nonNational_En = NETWORKS.filter(
          (network) => !network.isNational && !network.isEnglish
        ).map((network) => network.code);

        result = networks.filter((network) => nonNational_En.includes(network));
      }
      break;

    case "Home RSN (FR)":
      if (event.home_networks) {
        const networks = event.home_networks.split(",");

        const nonNational_En = NETWORKS.filter(
          (network) => !network.isNational && !network.isEnglish
        ).map((network) => network.code);

        result = networks.filter((network) => nonNational_En.includes(network));
      }
      break;

    case "US National":
      if (event.national_networks) {
        const networks = event.national_networks.split(",");
        const US_networks = NETWORKS.filter((network) => network.isUS).map(
          (network) => network.code
        );

        result = networks.filter((network) => US_networks.includes(network));
      }
      break;

    case "CA National":
      if (event.national_networks) {
        let networks = event.national_networks.split(",");

        let CA_networks_En = NETWORKS.filter((network) => !network.isUS && network.isEnglish).map(
          (network) => network.code
        );

        result = networks.filter((network) => CA_networks_En.includes(network));
      }
      break;

    case "CA National (FR)":
      if (event.national_networks) {
        const networks = event.national_networks.split(",");

        const CA_networks_Fr = NETWORKS.filter(
          (network) => !network.isUS && !network.isEnglish
        ).map((network) => network.code);

        result = networks.filter((network) => CA_networks_Fr.includes(network));
      }
      break;
    default:
      result = [];
  }

  return result;
};

// lambda function invocation
export const invokeLambda = (lambda, params) =>
  new Promise((resolve, reject) => {
    lambda.invoke(params, (error, data) => {
      if (error) {
        reject(error);
      } else {
        resolve(data);
      }
    });
  });
export const dbLambda = async (payload, functionName = "hub2-api-event-get") => {
  let params = {
    FunctionName: functionName,
    Payload: JSON.stringify(payload)
  };
    
  const credentials = await Auth.currentCredentials();
  const lambda = new AWS.Lambda({
      credentials: Auth.essentialCredentials(credentials)
  });
  const result = await invokeLambda(lambda, params);

  return JSON.parse(result.Payload);
};
export const dbLambdaNew = async (payload, functionName = "hub2-api-venue-post") => {
  let params = {
    FunctionName: functionName,
    Payload: JSON.stringify(payload)
  };
  const lambda = new AWS.Lambda();
  const result = await invokeLambda(lambda, params);
  return JSON.parse(result.Payload);
};
export const dbLambdaS3 = async (payload, functionName = "hub2-media-convert") => {
  let params = {
    FunctionName: functionName,
    Payload: JSON.stringify(payload)
  };
  const lambda = new AWS.Lambda();
  const result = await invokeLambda(lambda, params);
  //
  return JSON.parse(result.Payload);
};

export function getFileNameFromUrl(fileUrl) {
  let fileName = fileUrl;
  const n = fileUrl.lastIndexOf("/");
  if (n > -1)
    // does the url contain any '/' characters?
    fileName = fileUrl.substring(n + 1);
  return fileName;
}
export const isNumeric = (str) => {
  if (typeof str !== "string") return false; // we only process strings!
  return (
    !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
    !isNaN(parseFloat(str))
  ); // ...and ensure strings of whitespace fail
};
export const downloadMedia = async (keyWithPrefix, bucket) => {
  // as we are downloading from AWS S3 bucket,
  // we presume to have either 'cloudfront.net' 
  // or 'amazonaws.com' in keyWithPrefix
  let key = keyWithPrefix.includes('.net/') ?
      keyWithPrefix.split('.net/')[1] :
      keyWithPrefix.split('.com/')[1];

  const downloadFile = (bytesArray, fileName) => {
      // npm install js-file-download --save
      // alternative
      //FileDownload(result.Body, fileName); 
      const blob = new Blob([bytesArray]);
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = fileName;
      link.click();
  }

  let fileName = getFileNameFromUrl(key);
  try {
      
      const options = {
          Bucket: bucket,
          Key: key,
          ResponseContentType: 'application/json'
      }; 
      const credentials = await Auth.currentCredentials();
      const S3 = new AWS.S3({ 
          signatureVersion: 'v4', 
          httpOptions: { timeout: 1000 * 86400 },
          credentials: Auth.essentialCredentials(credentials)
      });
      const data = await S3.getObject(options).promise();

      downloadFile(data.Body, fileName);
  } catch (e) {
      throw new Error(`Could not retrieve file from S3: ${e.message}`)
  }
}
export const getUploadedFileDimensions = (file) =>
  new Promise((resolve, reject) => {
    try {
      let img = new Image();
      img.onload = () => {
        const width = img.naturalWidth,
          height = img.naturalHeight;
        window.URL.revokeObjectURL(img.src);
        return resolve({ width, height });
      };
      img.src = window.URL.createObjectURL(file);
    } catch (exception) {
      return reject(exception);
    }
  });

export const isFileAnImage = (file) => {
  const validImageExtensions = [
    "jpg",
    "jpeg",
    "png",
    "bmp",
    "svg",
    "jfif",
    "pjpeg",
    "pjp",
    "svg",
    "webp",
    "gif",
    "apng",
    "avif"
  ];

  const extension = file.name.substring(file.name.lastIndexOf(".") + 1).toLowerCase();

  if (validImageExtensions.includes(extension)) {
    return true;
  }

  return false;
};

export const convertSeconds = (totalSeconds) => {
  //let hours = Math.floor(totalSeconds / 3600);
  totalSeconds %= 3600;
  let minutes = Math.floor(totalSeconds / 60);
  let seconds = totalSeconds % 60;
  // leading zeroes
  minutes = String(minutes).padStart(2, "0");
  //hours = String(hours).padStart(2, "0");
  seconds = String(seconds).padStart(2, "0");
  // return hours + ":" + minutes + ":" + seconds;
  return minutes + ":" + seconds;
};
// insert string in filename before extension suffix
// e.g. public/file.jpg -> public/file_resized.jpg
export const appendToFilename = (filename, string) => {
  const dotIndex = filename.lastIndexOf(".");
  if (dotIndex === -1) return filename + string;
  else return filename.substring(0, dotIndex) + string + filename.substring(dotIndex);
};
export const contextMenuFunction = (obj, x, y, _e) => {
  var items = [];

  // click on upper part of column
  if (y !== null) {
    if (x) {
      if (obj.options.allowComments === true) {
        items.push({ type: "line" });

        var title = obj.records[y][x].getAttribute("title") || "";

        items.push({
          title: title ? obj.options.text.editComments : obj.options.text.addComments,
          onclick: function () {
            obj.setComments([x, y], prompt(obj.options.text.comments, title));
          }
        });

        if (title) {
          items.push({
            title: obj.options.text.clearComments,
            onclick: function () {
              obj.setComments([x, y], "");
            }
          });
        }
      }
    }
  }

  // Line
  items.push({ type: "line" });

  // Save
  if (obj.options.allowExport) {
    items.push({
      title: "Save to CSV", // title: obj.options.text.saveAs,
      shortcut: "Ctrl + S",
      onclick: function () {
        obj.download();
      }
    });
  }

  return items;
};
