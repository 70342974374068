import { defaultTheme, withAuthenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import { Amplify, Auth } from "aws-amplify";
import classNames from "classnames";
import { Button } from "primereact/button";
import { MultiSelect } from "primereact/multiselect";
import React, { useEffect, useState, lazy, Suspense } from "react";
import { Route } from "react-router-dom";
import AppFooter from "./AppFooter";
import AppMenu from "./AppMenu";
import AppTopBar from "./AppTopbar";
import { NoResource } from "./pages/404";
import { CalendarPage } from "./pages/CalendarPage";
import UserService from "./service/UserService";
import "primeflex/primeflex.css";
import "primeicons/primeicons.css";
import "primereact/resources/primereact.min.css";
import "./App.scss";
import "./App.css";
import config from "./aws-exports";
import { Dialog } from "primereact/dialog";

import { HUB_TYPE, MENU_ITEMS } from "./utilities/constants";
const PreviewPage = lazy(() =>
  import("./pages/PreviewPage").then((module) => ({ default: module.PreviewPage }))
);
const AdvertsPage = lazy(() => import("./pages/AdvertsPage"));
const ApprovalsPage = lazy(() => import("./pages/ApprovalsPage"));
const CompetitionPage = lazy(() => import("./pages/CompetitionPage"));
const CompetitionsPage = lazy(() => import("./pages/CompetitionsPage"));
const ContactsPage = lazy(() => import("./pages/ContactsPage"));
const EmptyPage = lazy(() => import("./pages/EmptyPage"));
const FaqPage = lazy(() => import("./pages/FaqPage"));
const GuidelinesPage = lazy(() => import("./pages/GuidelinesPage"));
const PlaylistPage = lazy(() => import("./pages/PlaylistPage"));
const ReportingPage = lazy(() => import("./pages/ReportingPage"));
const SettingsPage = lazy(() => import("./pages/SettingsPage"));
const SVAdvertPage = lazy(() => import("./pages/SVAdvertPage"));
const SVAdvertsPage = lazy(() => import("./pages/SVAdvertsPage"));
const SVPlaylistPage = lazy(() => import("./pages/SVPlaylistPage"));
const UsersPage = lazy(() => import("./pages/UsersPage"));
const VenuePage = lazy(() => import("./pages/VenuePage"));
const AdvertPage = lazy(() => import("./pages/AdvertPage"));
const AdvertiserPage = lazy(() => import("./pages/AdvertiserPage"));

const AWS = require("aws-sdk");

AWS.config.update({
  region: "eu-central-1"
});

Amplify.configure(config);
Auth.configure(config);

const App = () => {
  const [menuActive, setMenuActive] = useState(false);
  const [menuMode] = useState("static");
  const [menu, setMenu] = useState(null);

  const [colorScheme] = useState("light");
  const [menuTheme] = useState("layout-sidebar-darkgray");
  const [overlayMenuActive, setOverlayMenuActive] = useState(false);
  const [staticMenuDesktopInactive, setStaticMenuDesktopInactive] = useState(false);
  const [staticMenuMobileActive, setStaticMenuMobileActive] = useState(false);
  const [searchActive, setSearchActive] = useState(false);
  const [topbarUserMenuActive, setTopbarUserMenuActive] = useState(false);
  const [topbarNotificationMenuActive, setTopbarNotificationMenuActive] = useState(false);
  const [, setRightMenuActive] = useState(false);
  const [configActive, setConfigActive] = useState(false);
  const [inputStyle] = useState("outlined");
  const [ripple] = useState(false);

  const [user, setUser] = useState({ username: "Test User" }); //, role: 'user'});
  const [multiselectValue, setMultiselectValue] = useState(null);
  const [settingsDialog, setSettingsDialog] = useState(false);
  const multiselectValues = [
    { name: "Australia", code: "AU" },
    { name: "Brazil", code: "BR" },
    { name: "China", code: "CN" },
    { name: "Egypt", code: "EG" },
    { name: "France", code: "FR" },
    { name: "Germany", code: "DE" },
    { name: "India", code: "IN" },
    { name: "Japan", code: "JP" },
    { name: "Spain", code: "ES" },
    { name: "United States", code: "US" }
  ];

  let menuClick = false;
  let searchClick = false;
  let userMenuClick = false;
  let notificationMenuClick = false;
  let rightMenuClick = false;
  let configClick = false;

  const routers = [
    {
      path: "/",
      component: CalendarPage,
      exact: true,
      meta: { breadcrumb: [{ parent: "Pages", label: "Event Calendar" }] }
    },
    {
      path: "/adverts",
      component: AdvertsPage,
      meta: { breadcrumb: [{ parent: "Pages", label: "Advertisements" }] }
    },
    {
      path: "/advert/:id",
      component: AdvertPage,
      meta: { breadcrumb: [{ parent: "Pages", label: "Advertisement" }] }
    },
    {
      path: "/advertisers",
      component: AdvertiserPage,
      meta: { breadcrumb: [{ parent: "Pages", label: "Advertisers" }] }
    },
    {
      path: "/approvals",
      component: ApprovalsPage,
      meta: { breadcrumb: [{ parent: "Pages", label: "Approvals" }] }
    },
    {
      path: "/calendar",
      component: CalendarPage,
      meta: { breadcrumb: [{ parent: "Pages", label: "Event Calendar" }] }
    },
    {
      path: "/competitions",
      component: CompetitionsPage,
      meta: { breadcrumb: [{ parent: "Pages", label: "Seasons" }] }
    },
    {
      path: "/competition/:id",
      component: CompetitionPage,
      meta: { breadcrumb: [{ parent: "Pages", label: "Season" }] }
    },
    {
      path: "/contacts",
      component: ContactsPage,
      meta: { breadcrumb: [{ parent: "Pages", label: "Contacts" }] }
    },
    {
      path: "/empty",
      component: EmptyPage,
      meta: { breadcrumb: [{ parent: "Pages", label: "Empty Page" }] }
    },
    {
      path: "/faq",
      component: FaqPage,
      meta: { breadcrumb: [{ parent: "Pages", label: "Frequently Asked Questions" }] }
    },
    {
      path: "/guidelines",
      component: GuidelinesPage,
      meta: { breadcrumb: [{ parent: "Pages", label: "Guidelines" }] }
    },
    {
      path: "/preview/:id",
      component: PreviewPage,
      meta: { breadcrumb: [{ parent: "Pages", label: "3D Preview" }] }
    },
    {
      path: "/404",
      component: NoResource,
      meta: { breadcrumb: [{ parent: "Pages", label: "Resource not available" }] }
    },
    {
      path: "/playlist/:id",
      component: PlaylistPage,
      meta: { breadcrumb: [{ parent: "Pages", label: "Playlist Manager (DED)" }] }
    },
    {
      path: "/playlist-sv/:id",
      component: SVPlaylistPage,
      meta: { breadcrumb: [{ parent: "Pages", label: "Playlist Manager (Slot Virtual)" }] }
    },
    {
      path: "/reporting",
      component: ReportingPage,
      meta: { breadcrumb: [{ parent: "Pages", label: "Reporting" }] }
    },
    {
      path: "/settings",
      component: SettingsPage,
      meta: { breadcrumb: [{ parent: "UI Kit", label: "Settings" }] }
    },
    {
      path: "/slot-virtual-ad/:id",
      component: SVAdvertPage,
      meta: { breadcrumb: [{ parent: "Pages", label: "Slot Virtual Advert" }] }
    },
    {
      path: "/slot-virtual",
      component: SVAdvertsPage,
      meta: { breadcrumb: [{ parent: "Pages", label: "Slot Virtual Ads" }] }
    },
    {
      path: "/users",
      component: UsersPage,
      meta: { breadcrumb: [{ parent: "Pages", label: "Users" }] }
    },
    {
      path: "/venues",
      component: VenuePage,
      meta: { breadcrumb: [{ parent: "Pages", label: "Venues" }] }
    }
  ];

  useEffect(() => {
    const userService = new UserService();
    userService.getUserData().then((data) => {
      setUser(data);

      const _menu = buildMenu(data);
      setMenu(_menu);
    });
  }, []);

  useEffect(() => {
    if (staticMenuMobileActive) {
      blockBodyScroll();
    } else {
      unblockBodyScroll();
    }
  }, [staticMenuMobileActive]);

  useEffect(() => {
    changeStyleSheetUrl("layout-css", "layout-" + colorScheme + ".css", 1);
    changeStyleSheetUrl("theme-css", "theme-" + colorScheme + ".css", 1);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const changeStyleSheetUrl = (id, value, from) => {
    const element = document.getElementById(id);
    const urlTokens = element.getAttribute("href").split("/");

    if (from === 1) {
      urlTokens[urlTokens.length - 1] = value;
    } else if (from === 2) {
      if (value !== null) {
        urlTokens[urlTokens.length - 2] = value;
      }
    } else if (from === 3) {
      urlTokens[urlTokens.length - 2] = value;
    }

    const newURL = urlTokens.join("/");

    replaceLink(element, newURL);
  };

  const replaceLink = (linkElement, href) => {
    if (isIE()) {
      linkElement.setAttribute("href", href);
    } else {
      const id = linkElement.getAttribute("id");
      const cloneLinkElement = linkElement.cloneNode(true);

      cloneLinkElement.setAttribute("href", href);
      cloneLinkElement.setAttribute("id", id + "-clone");

      linkElement.parentNode.insertBefore(cloneLinkElement, linkElement.nextSibling);

      cloneLinkElement.addEventListener("load", () => {
        linkElement.remove();
        cloneLinkElement.setAttribute("id", id);
      });
    }
  };

  const isIE = () => {
    return /(MSIE|Trident\/|Edge\/)/i.test(window.navigator.userAgent);
  };

  const onMenuitemClick = (event) => {
    if (!event.item.items) {
      hideOverlayMenu();

      if (isSlim()) {
        setMenuActive(false);
      }
    }
  };

  const onRootMenuitemClick = () => {
    setMenuActive((prevMenuActive) => !prevMenuActive);
  };

  const onTopbarUserMenuButtonClick = (event) => {
    userMenuClick = true;
    setTopbarUserMenuActive((prevTopbarUserMenuActive) => !prevTopbarUserMenuActive);

    hideOverlayMenu();

    event.preventDefault();
  };

  const onTopbarNotificationMenuButtonClick = (event) => {
    notificationMenuClick = true;
    setTopbarNotificationMenuActive(
      (prevTopbarNotificationMenuActive) => !prevTopbarNotificationMenuActive
    );

    hideOverlayMenu();

    event.preventDefault();
  };

  const toggleSearch = () => {
    setSearchActive((prevSearchActive) => !prevSearchActive);
    searchClick = true;
  };

  const onSearchHide = () => {
    setSearchActive(false);
    searchClick = false;
  };

  const onRightMenuButtonClick = (event) => {
    rightMenuClick = true;
    setRightMenuActive((prevRightMenuActive) => !prevRightMenuActive);
    hideOverlayMenu();
    event.preventDefault();
  };

  const hideOverlayMenu = () => {
    setOverlayMenuActive(false);
    setStaticMenuMobileActive(false);
    unblockBodyScroll();
  };

  const blockBodyScroll = () => {
    if (document.body.classList) {
      document.body.classList.add("blocked-scroll");
    } else {
      document.body.className += " blocked-scroll";
    }
  };

  const unblockBodyScroll = () => {
    if (document.body.classList) {
      document.body.classList.remove("blocked-scroll");
    } else {
      document.body.className = document.body.className.replace(
        new RegExp("(^|\\b)" + "blocked-scroll".split(" ").join("|") + "(\\b|$)", "gi"),
        " "
      );
    }
  };

  const isSlim = () => {
    return menuMode === "slim";
  };

  const isOverlay = () => {
    return menuMode === "overlay";
  };

  const isDesktop = () => {
    return window.innerWidth > 991;
  };

  const onDocumentClick = () => {
    if (!searchClick && searchActive) {
      onSearchHide();
    }

    if (!userMenuClick) {
      setTopbarUserMenuActive(false);
    }

    if (!notificationMenuClick) {
      setTopbarNotificationMenuActive(false);
    }

    if (!rightMenuClick) {
      setRightMenuActive(false);
    }

    if (!menuClick) {
      if (isSlim()) {
        setMenuActive(false);
      }

      if (overlayMenuActive || staticMenuMobileActive) {
        hideOverlayMenu();
      }

      unblockBodyScroll();
    }

    if (configActive && !configClick) {
      setConfigActive(false);
    }

    searchClick = false;
    configClick = false;
    userMenuClick = false;
    rightMenuClick = false;
    notificationMenuClick = false;
    menuClick = false;
  };

  const containerClassName = classNames(
    "layout-wrapper",
    {
      "layout-overlay": menuMode === "overlay",
      "layout-static": menuMode === "static",
      "layout-slim": menuMode === "slim",
      "layout-sidebar-dim": colorScheme === "dim",
      "layout-sidebar-dark": colorScheme === "dark",
      "layout-overlay-active": overlayMenuActive,
      "layout-mobile-active": staticMenuMobileActive,
      "layout-static-inactive": staticMenuDesktopInactive && menuMode === "static",
      "p-input-filled": inputStyle === "filled",
      "p-ripple-disabled": !ripple
    },
    colorScheme === "light" ? menuTheme : ""
  );
  const logOut = async () => {
    Auth.signOut({});
  };
  const handleSettingsClick = () => {
    openSettingsPage();
  };
  const openSettingsPage = () => {};

  const hideSettingsDialog = () => {
    setSettingsDialog(false);
  };

  const onMenuButtonClick = (event) => {
    menuClick = true;
    setTopbarUserMenuActive(false);
    setTopbarNotificationMenuActive(false);
    setRightMenuActive(false);

    if (isOverlay()) {
      setOverlayMenuActive((prevOverlayMenuActive) => !prevOverlayMenuActive);
    }

    if (isDesktop()) {
      setStaticMenuDesktopInactive(
        (prevStaticMenuDesktopInactive) => !prevStaticMenuDesktopInactive
      );
    } else {
      setStaticMenuMobileActive((prevStaticMenuMobileActive) => !prevStaticMenuMobileActive);
    }

    event.preventDefault();
  };

  const settingsDialogFooter = (
    <>
      <Button
        label="Cancel"
        icon="pi pi-times"
        className="p-button-text"
        onClick={hideSettingsDialog}
      />
      <Button
        label="Save"
        icon="pi pi-check"
        // onClick={() => {saveComp();}}
      />
    </>
  );
  const getMenuItems = (ids) => {
    const items = MENU_ITEMS;
    return items.filter((item) => {
      if (ids.includes(item.id)) return { label: item.label, icon: item.icon, to: item.to };
    });
  };

  const intsStringConversion = (intsOrString, toInts = true) => {
    const separator = ",";

    if (toInts) return intsOrString.split(separator).map(Number);
    else return intsOrString.join(separator);
  };

  const parseUserSettings = (settingsString) => {
    const startString = "menu:";
    const endString = ";";
    let finalArray = [1, 4, 5];
    if (settingsString) {
      // Split settings string into two parts,
      // before the 'menu:' and after the 'menu:'.
      // Example: '000menu:111;bla' => ['000', '111;bla']
      const splittedArr = settingsString.split(startString);
      // if there's menu settings in the string
      if (splittedArr.length > 1) {
        let str = splittedArr[1];
        const before = str.slice(0, str.indexOf(endString));
        finalArray = intsStringConversion(before);
      }
    }
    return finalArray;
  };

  // TODO: conditionally add favorites (if exist)
  const buildMenu = (user, ints = null) => {
    let _menu = [];

    if (HUB_TYPE !== "NHL") {
      // favorites section
      const menuFavorites = ints ? ints : parseUserSettings(user.settings);
      _menu = [
        { label: "Favorites", icon: "pi pi-fw pi-home", items: getMenuItems(menuFavorites) }
      ];
      _menu.push({ separator: true });

      _menu = [
        ..._menu,
        ...[
          {
            label: "Main",
            icon: "pi pi-fw pi-id-card",
            items: getMenuItems([1, 2, 3, 4, 5, 6, 7, 8, 9, 10])
          }
        ]
      ];

      if (user.role === "admin") {
        _menu.push({ separator: true });
        _menu = [
          ..._menu,
          ...[{ label: "Admin", icon: "pi pi-fw pi-id-user", items: getMenuItems([11, 12, 13]) }]
        ];
      }
    } else {
      // main section
      _menu = [
        ..._menu,
        ...[
          {
            label: "Main",
            icon: "pi pi-fw pi-id-card",
            items: getMenuItems([1, 4, 2, 3, 12, 5, 13, 15, 14])
          }
        ]
      ];

      if (user.club === 1 && user.role === "admin") {
        _menu.push({ separator: true });
        _menu = [
          ..._menu,
          ...[
            {
              label: "Admin",
              icon: "pi pi-fw pi-id-user",
              items: getMenuItems([6, 7, 8, 9, 10, 11])
            }
          ]
        ];
      }
    }
    return _menu;
  };

  const onMenuClick = () => {
    menuClick = true;
  };

  const updateGlobalState = (operation, args) => {
    if (operation === "saveFavorites") {
      setMenu(
        buildMenu(
          user,
          args.map((arg) => arg.code)
        )
      );
    }
  };

  if (!user.role) return null;
  else
    return (
      <div className={containerClassName} data-theme={colorScheme} onClick={onDocumentClick}>
        <div className="layout-content-wrapper">
          <AppTopBar
            routers={routers}
            topbarNotificationMenuActive={topbarNotificationMenuActive}
            topbarUserMenuActive={topbarUserMenuActive}
            onMenuButtonClick={onMenuButtonClick}
            onSearchClick={toggleSearch}
            onTopbarNotification={onTopbarNotificationMenuButtonClick}
            onTopbarUserMenu={onTopbarUserMenuButtonClick}
            onRightMenuClick={onRightMenuButtonClick}
            onRightMenuButtonClick={onRightMenuButtonClick}
            onLogout={logOut}
            userEmail={user.email}
            onSettingsClick={handleSettingsClick}>
            {" "}
          </AppTopBar>

          <div className="layout-content">
            {routers.map((router, index) => {
              if (router.exact) {
                return (
                  <Suspense fallback={<div className="lds-ellipsis"></div>} key={`router${index}`}>
                    <Route
                      path={router.path}
                      exact
                      render={(props) => <router.component {...props} user={user} />}
                    />
                  </Suspense>
                );
              }

              return (
                <Suspense fallback={<div className="lds-ellipsis"></div>} key={`router${index}`}>
                  <Route
                    path={router.path}
                    render={(props) => (
                      <router.component
                        {...props}
                        user={user}
                        updateGlobalState={updateGlobalState}
                      />
                    )}
                  />
                </Suspense>
              );
            })}
          </div>

          <AppFooter />
        </div>
        <AppMenu
          model={menu}
          menuMode={menuMode}
          active={menuActive}
          mobileMenuActive={staticMenuMobileActive}
          onMenuClick={onMenuClick}
          onMenuitemClick={onMenuitemClick}
          onRootMenuitemClick={onRootMenuitemClick}></AppMenu>

        <div className="layout-mask modal-in"></div>

        <Dialog
          visible={settingsDialog}
          style={{ width: "450px" }}
          id="settingsDialog"
          header="Confirm"
          modal
          footer={settingsDialogFooter}
          onHide={hideSettingsDialog}>
          <div className="p-d-flex p-ai-center p-jc-center">
            <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: "2rem" }} />

            <h5>MultiSelect</h5>
            <MultiSelect
              value={multiselectValue}
              onChange={(e) => setMultiselectValue(e.value)}
              options={multiselectValues}
              optionLabel="name"
              placeholder="Select Countries"
              filter
              className="multiselect-custom"
            />
          </div>
        </Dialog>
      </div>
    );
};

const theme = {
  ...defaultTheme,
  container: {
    ...defaultTheme.container,
    padding: 0,
    marginTop: "-8px"
  },
  navBar: {
    ...defaultTheme.navBar,
    background: "#dbe8f4",
    backgroundImage: "-webkit-linear-gradient(top,#08364e,#094361)",
    boxShadow:
      "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
    border: 0
  },
  button: {
    ...defaultTheme.button,
    backgroundColor: "#41769c",
    color: "white"
  },
  sectionBody: {
    ...defaultTheme.sectionBody,
    padding: "0px"
  },
  sectionHeader: {
    ...defaultTheme.sectionHeader,
    backgroundColor: "var(--squidInk)",
    display: "none"
  },

  oAuthSignInButton: {
    ...defaultTheme.oAuthSignInButton,
    width: 186,
    height: 58,
    fontSize: 16,

    background: "linear-gradient(90deg, #54979e 0%, #296670 99%)",
    padding: 0,
    paddingTop: 1,
    margin: "120px auto",
    border: 0,
    color: "white"
  }
};

// https://techinscribed.com/authentication-in-serverless-react-application-using-aws-amplify/
const signUpConfig = {
  header: "Create an Account",
  hideAllDefaults: true,
  defaultCountryCode: "1",
  signUpFields: [
    {
      label: "Name",
      key: "name",
      required: true,
      displayOrder: 1,
      type: "string"
    },
    {
      label: "Email",
      key: "username",
      required: true,
      displayOrder: 2,
      type: "string"
    },
    {
      label: "Password",
      key: "password",
      required: true,
      displayOrder: 3,
      type: "password"
    }
  ]
};

export default withAuthenticator(App, {
  includeGreetings: false,
  signUpConfig,
  federated: null,
  theme: theme
});
